import { Component, OnInit } from '@angular/core';
import { CustomRouterService } from 'src/app/services/custom-router.service';

@Component({
  selector: 'calendar',
  styleUrls: ['./calendar.component.scss'],
  templateUrl: './calendar.component.html'
})
export class CalendarComponent implements OnInit {

  constructor(
    private customRouterService: CustomRouterService
  ) {
    this.customRouterService = customRouterService;
  }

  public ngOnInit(): void {
    this.customRouterService.setLoadingState(true);
    this.initComponent();
    this.customRouterService.setLoadingState(false);
  }

  private initComponent(): void {
    this.customRouterService.setNewComponentName('Calendrier');
  }
}
