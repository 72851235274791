export class CircularQueue<T> {
  public capacity: number;
  public data: T[];

  public constructor(capacity: number, arr?: T[]) {
      this.capacity = capacity;
      this.data = [];
      this.fillQueue(arr);
  }

  public push(element: T): CircularQueue<T> {
      if (this.data.length === this.capacity) {
          this.shiftArrange();
          this.data[this.data.length - 1] = element;
      } else {
          this.data.push(element);
      }
      return this;
  }

  public pop(): CircularQueue<T> {
      this.data.splice(0, 1);
      return this;
  }

  public nextOut(): T {
      if (this.data.length > 0) {
          return this.data[0];
      } else {
          return undefined;
      }
  }

  public lastOut(): T {
      if (this.data.length > 0) {
          return this.data[this.data.length - 1];
      } else {
          return undefined;
      }
  }

  public anteLastOut(): T {
      if (this.data.length > 1) {
          return this.data[this.data.length - 2];
      } else {
          return undefined;
      }
  }

  private fillQueue(arr: T[]): void {
      if (arr !== undefined && arr !== null) {
          this.data = JSON.parse(JSON.stringify(arr));
      }
  }

  private shiftArrange(): void {
      if (this.data.length > 1) {
          for (let i = 0; i < this.data.length - 1; i++) {
              this.data[i] = this.data[i + 1];
          }
      }
  }
}
