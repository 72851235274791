import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddEventModalComponent } from '../modals/add-event-modal';
import { ToastrService } from 'ngx-toastr';
import { Room } from 'src/app/models/responses/rooms/room.model';
import { Group } from 'src/app/models/responses/groups/group.model';
import { PostEventRequest } from 'src/app/models/requests/events/postEventRequest.model';
import { Team } from 'src/app/models/responses/teams/team.model';

@Component({
    selector: 'app-add-event-btn',
    templateUrl: './add-event-btn.component.html',
    styleUrls: ['./add-event-btn.component.scss']
})
export class AddEventBtnComponent implements OnInit {

    @Input() public allRooms: Room[];
    @Input() public allGroups: Group[]
    @Input() public allTeams: Team[];
    @Input() public disabled: boolean;

    @Output() public onClosedModal: EventEmitter<PostEventRequest> = new EventEmitter();

    public constructor(
        private modalService: ModalService,
        private toastrService: ToastrService
    ) {
        this.modalService = modalService;
        this.toastrService = toastrService;
    }

    ngOnInit() { }

    public onAddEvent(): void {
        const modalRef: NgbModalRef = this.modalService.open(AddEventModalComponent);
        modalRef.componentInstance.allRooms = this.allRooms;
        modalRef.componentInstance.allGroups = this.allGroups;
        modalRef.componentInstance.allTeams = this.allTeams;
        modalRef.result
            .then((result: PostEventRequest): void => this.onClosedModal.emit(result))
            .catch((): void => this.onClosedModal.emit(null));
    }
}
