import { Injectable } from '@angular/core';
import { UpdatePinCodeRequest } from 'src/app/models/requests/account/updatePinCode.model';
import { AccountData } from '../data-access/account.data';

@Injectable()
export class AccountBusiness {

    private accountData: AccountData;

    public constructor(accountData: AccountData) {
        this.accountData = accountData;
    }

    public async updatePinCode(pinCode: number): Promise<void> {
      return this.accountData.updatePinCode({ pinCode } as UpdatePinCodeRequest);
    }
}
