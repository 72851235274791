import { Injectable } from '@angular/core';
import { EventsData } from '../data-access/events.data';
import { GetRecentEventsResponse } from 'src/app/models/responses/events/getRecentEvents/getRecentEvents.model';
import { GetTodayEventsResponse } from 'src/app/models/responses/events/getTodayEvents/getTodayEvents.model';
import { GetEventsRequest } from 'src/app/models/requests/events/getEventsRequest.model';
import { GetEventsResponse } from 'src/app/models/responses/events/getEvents/getEventsResponse.model';
import { GetEventTypesResponse } from 'src/app/models/responses/events/getEventTypes/getEventTypesResponse.model';
import { PostEventRequest } from 'src/app/models/requests/events/postEventRequest.model';
import { GetEventsSingleEvent } from 'src/app/models/responses/events/getEvents/getEventsSingleEvent.model';
import { GetPresenceListResponse } from 'src/app/models/responses/events/getPresenceListByEvent/getPresenceListResponse.model';
import { FullEvent } from 'src/app/models/responses/events/getEvent/fullEvent.model';
import { Presence } from 'src/app/models/responses/events/getPresenceListByEvent/presence.model';
import { GetComingEventsResponse } from 'src/app/models/responses/events/getComingEvents/getComingEvents.model';
import * as moment from 'moment';
import { GetNbEventByDayResponse } from 'src/app/models/responses/events/getNbEventByDay/getNbEventByDayResponse.model';
import { NgbDateHelperService } from 'src/app/services/ngbDateHelper.service';
import { UpdateEventRequest } from 'src/app/models/requests/events/updateEventRequest.model';
import { AddStudentListToPresenceListRequest } from 'src/app/models/requests/events/addStudentListToPresenceListRequest.model';

@Injectable()
export class EventsBusiness {

    public constructor(private eventsData: EventsData, private ngbDateHelperService: NgbDateHelperService) {
        this.eventsData = eventsData;
        this.ngbDateHelperService = ngbDateHelperService;
    }

    public async getRecentEvents(): Promise<GetRecentEventsResponse> {
        return this.eventsData.getRecentEvents();
    }

    public async getTodayEvents(): Promise<GetTodayEventsResponse> {
        return this.eventsData.getTodayEvents();
    }

    public async getComingEvents(): Promise<GetComingEventsResponse> {
        return this.eventsData.getComingEvents();
    }

    public async getEvents(request: GetEventsRequest): Promise<GetEventsResponse> {
        return this.eventsData.getEvents(request);
    }

    public async getEventTypes(): Promise<GetEventTypesResponse> {
        return this.eventsData.getEventTypes();
    }

    public async postEvent(request: PostEventRequest): Promise<void> {
        return this.eventsData.postEvent(request);
    }

    public async deleteEvent(eventId: number): Promise<void> {
        return this.eventsData.deleteEvent(eventId);
    }

    public async getEvent(eventId: number): Promise<FullEvent> {
        const fullEvent: FullEvent = new FullEvent();
        const infos: GetEventsSingleEvent = await this.eventsData.getEvent(eventId);
        infos.startDateCasted = new Date(infos.startDate);
        infos.endDateCasted = new Date(infos.endDate);
        const presenceListResponse: GetPresenceListResponse = await this.eventsData.getPresenceListByEvent(eventId);
        presenceListResponse.presenceList.forEach((presence: Presence) => {
            presence.presenceDateCasted = new Date(presence.presenceDate)
        });
        return Object.assign(fullEvent, {
            infos,
            presenceList: presenceListResponse
        });
    }

    public async getNbEventByDay(startDate: Date): Promise<GetNbEventByDayResponse> {
        const viableStartDate: Date = moment(startDate).startOf('month').subtract(10, 'days').toDate();
        const nextMonthDate: Date = moment(startDate).startOf('month').add(40, 'days').toDate();
        return this.eventsData.getNbEventByDay({
            startDate: this.ngbDateHelperService.toStringifyFromDate(viableStartDate),
            endDate: this.ngbDateHelperService.toStringifyFromDate(nextMonthDate) 
        });
    }

    public async removeStudentFromAttendanceList(eventId: number, studentId: number): Promise<void> {
        return this.eventsData.removeStudentFromAttendanceList(eventId, studentId);
    }

    public async updateEvent(request: UpdateEventRequest): Promise<void> {
        return this.eventsData.updateEvent(request);
    }

    public async addStudentListToAttendanceList(eventId: number, request: AddStudentListToPresenceListRequest): Promise<void> {
        return this.eventsData.addStudentListToAttendanceList(eventId, request);
    }
}
