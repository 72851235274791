import { Component, OnInit } from '@angular/core';
import { CustomAnimationService } from 'src/app/services/custom-animation.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public constructor(
    private animationService: CustomAnimationService
  ) {
    this.animationService = animationService;
  }

  public ngOnInit() {
    this.animateOnInit();
  }

  public animateOnInit(): void {
    const root: HTMLElement = document.getElementById('root-footer');
    this.animationService.animateOpacityTranslateFromBottom(root, 0);
  }

}
