import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Room } from 'src/app/models/responses/rooms/room.model';
import { MultipleSelectPanelHandler } from 'src/app/models/multipleSelectPanelHandler.model';
import { Group } from 'src/app/models/responses/groups/group.model';
import { PostEventRequest } from 'src/app/models/requests/events/postEventRequest.model';
import { Team } from 'src/app/models/responses/teams/team.model';
import * as moment from 'moment';
import { NgbDateHelperService } from 'src/app/services/ngbDateHelper.service';
import { EventsBusiness } from 'src/app/api/business/events.business';
import { EventType } from 'src/app/models/responses/events/getEventTypes/eventType.model';
import { GetEventTypesResponse } from 'src/app/models/responses/events/getEventTypes/getEventTypesResponse.model';

@Component({
    selector: 'app-add-event-modal',
    templateUrl: './add-event-modal.component.html',
    styleUrls: ['./add-event-modal.component.scss']
})
export class AddEventModalComponent implements OnInit {

    @Input() public allRooms: Room[];
    @Input() public allGroups: Group[];
    @Input() public allTeams: Team[];
    
    public disableAction: boolean;
    public multipleSelectPanelHandler: MultipleSelectPanelHandler;
    public formSubmitted: boolean;
 
    public startDatePicked: NgbDateStruct;
    public startHourFromSelect: string;
    public startMinFromSelect: string;
    public endDatePicked: NgbDateStruct;
    public endHourFromSelect: string;
    public endMinFromSelect: string;

    public eventTypes: EventType[];

    public postEventRequest: PostEventRequest;

    constructor(
        private activeModal: NgbActiveModal,
        private ngbDateHelperService: NgbDateHelperService,
        private eventsBusiness: EventsBusiness
    ) {
        this.activeModal = activeModal;
        this.ngbDateHelperService = ngbDateHelperService;
        this.eventsBusiness = eventsBusiness;
    }

    public ngOnInit(): void {
        this.initComponent();
        this.loadData();
    }

    public generateIterable(length: number): Array<number> {
        return [...new Array(length).keys()];
    }

    public getRoomsTitleComponent(): string {
        if (this.postEventRequest === undefined || this.postEventRequest.roomIdList === undefined || this.postEventRequest.roomIdList.length === 0) {
            return 'Salle(s)...';
        } else {
            return this.allRooms
            .filter((room: Room) => this.postEventRequest.roomIdList.includes(room.id))
            .map((room: Room) => room.name)
            .join(', ');
        }
    }

    public getGroupsTitleComponent(): string {
        if (this.postEventRequest === undefined || this.postEventRequest.groupIdList === undefined || this.postEventRequest.groupIdList.length === 0) {
            return 'Groupe(s)...';
        } else {
            return this.allGroups
            .filter((group: Group) => this.postEventRequest.groupIdList.includes(group.id))
            .map((group: Group) => group.name)
            .join(', ');
        }
    }

    public getTeamsTitleComponent(): string {
        if (this.postEventRequest === undefined || this.postEventRequest.teamIdList === undefined || this.postEventRequest.teamIdList.length === 0) {
            return 'Équipe(s)...';
        } else {
            return this.allTeams
            .filter((team: Team) => this.postEventRequest.teamIdList.includes(team.id))
            .map((team: Team) => team.name)
            .join(', ');
        }
    }

    public isFormReadyToSend(): boolean {
        return this.postEventRequest !== undefined
        && this.postEventRequest.roomIdList !== undefined && this.postEventRequest.roomIdList.length > 0
        && this.postEventRequest.groupIdList !== undefined && this.postEventRequest.groupIdList.length > 0
        && this.postEventRequest.name !== undefined && this.isNameValid()
        && this.postEventRequest.startDate !== undefined
        && this.postEventRequest.endDate !== undefined
        && this.isDateValid()
        && this.postEventRequest.eventTypeId !== undefined;
    }

    public isDateValid(): boolean {
        return moment(this.postEventRequest.startDate).isBefore(this.postEventRequest.endDate);
    }

    public isNameValid(): boolean {
        return this.postEventRequest.name !== undefined && this.postEventRequest.name.length !== 0;
    }

    public onModifiedRoomFilter(rooms: Room[]): void {
        this.postEventRequest.roomIdList = rooms.map((room: Room) => room.id);
    }

    public onModifiedGroupFilter(groups: Group[]): void {
        this.postEventRequest.groupIdList = groups.map((group: Group) => group.id);
    }

    public onModifiedTeamFilter(teams: Team[]): void {
        this.postEventRequest.teamIdList = teams.map((team: Team) => team.id);
    }

    public onToggleRoomsPanel(isShown: boolean): void {
        this.multipleSelectPanelHandler.showRooms = isShown;
        Object.entries(this.multipleSelectPanelHandler)
            .forEach(([key, value]) => {
                if (key !== 'showRooms' && value && this.multipleSelectPanelHandler.showRooms) {
                    this.multipleSelectPanelHandler[key] = false;
                }
            });
    }

    public onToggleGroupsPanel(isShown: boolean): void {
        this.multipleSelectPanelHandler.showGroups = isShown;
        Object.entries(this.multipleSelectPanelHandler)
            .forEach(([key, value]) => {
                if (key !== 'showGroups' && value && this.multipleSelectPanelHandler.showGroups) {
                    this.multipleSelectPanelHandler[key] = false;
                }
            });
    }

    public onToggleTeamsPanel(isShown: boolean): void {
        this.multipleSelectPanelHandler.showTeams = isShown;
        Object.entries(this.multipleSelectPanelHandler)
            .forEach(([key, value]) => {
                if (key !== 'showTeams' && value && this.multipleSelectPanelHandler.showTeams) {
                    this.multipleSelectPanelHandler[key] = false;
                }
            });
    }

    public onStartDateChanged(): void {
        const currHours: number = this.postEventRequest.startDate.getHours();
        const currMin: number = this.postEventRequest.startDate.getMinutes();
        const currDate: Date = this.ngbDateHelperService.toModel(this.startDatePicked);
        this.postEventRequest.startDate = moment(currDate).startOf('day').add(currHours, 'hours').add(currMin, 'minutes').toDate();
    }

    public onStartHourChanged(): void {
        const currMin: number = this.postEventRequest.startDate.getMinutes();
        this.postEventRequest.startDate = moment(this.postEventRequest.startDate).startOf('day').add(this.startHourFromSelect, 'hours').add(currMin, 'minutes').toDate();
    }

    public onStartMinChanged(): void {
        const currHours: number = this.postEventRequest.startDate.getHours();
        this.postEventRequest.startDate = moment(this.postEventRequest.startDate).startOf('day').add(currHours, 'hours').add(this.startMinFromSelect, 'minutes').toDate();
    }

    public onEndDateChanged(): void {
        const currHours: number = this.postEventRequest.endDate.getHours();
        const currMin: number = this.postEventRequest.endDate.getMinutes();
        const currDate: Date = this.ngbDateHelperService.toModel(this.endDatePicked);
        this.postEventRequest.endDate = moment(currDate).startOf('day').add(currHours, 'hours').add(currMin, 'minutes').toDate();
    }

    public onEndHourChanged(): void {
        const currMin: number = this.postEventRequest.endDate.getMinutes();
        this.postEventRequest.endDate = moment(this.postEventRequest.endDate).startOf('day').add(this.endHourFromSelect, 'hours').add(currMin, 'minutes').toDate();
    }

    public onEndMinChanged(): void {
        const currHours: number = this.postEventRequest.endDate.getHours();
        this.postEventRequest.endDate = moment(this.postEventRequest.endDate).startOf('day').add(currHours, 'hours').add(this.endMinFromSelect, 'minutes').toDate();
    }

    public loadData(): void {
        this.eventsBusiness.getEventTypes()
        .then((response: GetEventTypesResponse) => {
            this.eventTypes = response.eventTypeList;
            this.postEventRequest.eventTypeId = this.eventTypes[0].id;
        })
        .catch((err) => {
            console.log(err);
        })
    }

    public trySubmit(): void {
        this.formSubmitted = true;
        if (this.isFormReadyToSend()) {
            this.send();
        }
    }

    public dismiss(): void {
        this.activeModal.dismiss(false);
    }

    public send(): void {
        this.activeModal.close(this.postEventRequest);
    }

    private initComponent(): void {
        this.eventTypes = [];

        this.formSubmitted = false;

        this.postEventRequest = new PostEventRequest();
        this.postEventRequest.name = '';
        this.postEventRequest.roomIdList = [];
        this.postEventRequest.groupIdList = [];
        this.postEventRequest.teamIdList = [];

        const currDate: Date = new Date();
        this.startDatePicked = { day: currDate.getDate(), month: currDate.getMonth() + 1, year: currDate.getFullYear() };
        this.endDatePicked = { day: currDate.getDate(), month: currDate.getMonth() + 1, year: currDate.getFullYear() };
        this.postEventRequest.startDate = moment(new Date()).startOf('day').add(10, 'hours').toDate();
        this.postEventRequest.endDate = moment(new Date()).startOf('day').add(18, 'hours').toDate();
        this.startHourFromSelect = '10';
        this.startMinFromSelect = '0';
        this.endHourFromSelect = '18';
        this.endMinFromSelect = '0';
        this.multipleSelectPanelHandler = {
            showGroups: false,
            showRooms: false,
            showTeams: false
        };
    }
}