import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomRouterService } from 'src/app/services/custom-router.service';
import { MinifiedEvent } from 'src/app/models/responses/events/minifiedEvent.model';
import { EventsBusiness } from 'src/app/api/business/events.business';
import { ToastrService } from 'ngx-toastr';
import { GetRecentEventsResponse } from 'src/app/models/responses/events/getRecentEvents/getRecentEvents.model';
import { GetTodayEventsResponse } from 'src/app/models/responses/events/getTodayEvents/getTodayEvents.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from 'src/app/services/modal.service';
import { AddEventModalComponent } from 'src/app/components/modals/add-event-modal';
import { GroupsBusiness } from 'src/app/api/business/groups.business';
import { RoomsBusiness } from 'src/app/api/business/rooms.business';
import { GetRoomsResponse } from 'src/app/models/responses/rooms/getRoomsResponse.model';
import { GetGroupsResponse } from 'src/app/models/responses/groups/getGroupsReponse.model';
import { Room } from 'src/app/models/responses/rooms/room.model';
import { Group } from 'src/app/models/responses/groups/group.model';
import { PostEventRequest } from 'src/app/models/requests/events/postEventRequest.model';
import { GetComingEventsResponse } from 'src/app/models/responses/events/getComingEvents/getComingEvents.model';
import { GetNbEventByDayResponse } from 'src/app/models/responses/events/getNbEventByDay/getNbEventByDayResponse.model';
import { Team } from 'src/app/models/responses/teams/team.model';
import { TeamsBusiness } from 'src/app/api/business/teams.business';
import { GetTeamsReponse } from 'src/app/models/responses/teams/getTeamsResponse.model';

@Component({
  selector: 'app-home',
  styleUrls: ['./home.component.scss'],
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  public recentEvents: MinifiedEvent[];
  public todayEvents: MinifiedEvent[];
  public comingEvents: MinifiedEvent[];
  public currentlyDisplayedRecentEventIndex: number;
  public currentlyDisplayedTodayEventIndex: number;
  public currentlyDisplayedComingEventIndex: number;
  public allRooms: Room[];
  public allGroups: Group[];
  public allTeams: Team[];

  constructor(
    private customRouterService: CustomRouterService,
    private eventsBusiness: EventsBusiness,
    private roomsBusiness: RoomsBusiness,
    private groupsBusiness: GroupsBusiness,
    private teamsBusiness: TeamsBusiness,
    private toastrService: ToastrService,
    private modalService: ModalService
  ) {
    this.customRouterService = customRouterService;
    this.eventsBusiness = eventsBusiness;
    this.roomsBusiness = roomsBusiness;
    this.groupsBusiness = groupsBusiness;
    this.teamsBusiness = teamsBusiness;
    this.toastrService = toastrService;
    this.modalService = modalService;
  }

  public ngOnInit(): void {
    this.customRouterService.setLoadingState(true);
    this.initComponent();
    this.initData();
    this.loadData();
  }

  private initComponent(): void {
    this.customRouterService.setNewComponentName('Accueil');
  }

  private initData(): void {
    [this.recentEvents, this.todayEvents] = [[], []];
  }

  private loadData(): void {
    Promise.all([
      this.eventsBusiness.getRecentEvents(),
      this.eventsBusiness.getTodayEvents(),
      this.eventsBusiness.getComingEvents(),
      this.roomsBusiness.getRooms(),
      this.groupsBusiness.getGroups(),
      this.teamsBusiness.getTeams(),
      this.eventsBusiness.getNbEventByDay(new Date())
    ])
      .then(
        (
          res: [
            GetRecentEventsResponse,
            GetTodayEventsResponse,
            GetComingEventsResponse,
            GetRoomsResponse,
            GetGroupsResponse,
            GetTeamsReponse,
            GetNbEventByDayResponse
          ]
        ) => {
          this.recentEvents = res[0].eventList;
          this.todayEvents = res[1].eventList;
          this.comingEvents = res[2].eventList;
          this.currentlyDisplayedRecentEventIndex =
            this.recentEvents.length > 0 ? 0 : null;
          this.currentlyDisplayedTodayEventIndex =
            this.todayEvents.length > 0 ? 0 : null;
          this.currentlyDisplayedComingEventIndex =
            this.comingEvents.length > 0 ? 0 : null;
          this.allRooms = res[3].roomList;
          this.allGroups = res[4].groupList;
          this.allTeams = res[5].teamList;
        }
      )
      .catch((err: any) => {
        this.toastrService.error(
          'Impossible de récupérer les données depuis le serveur. Contactez un administrateur.',
          'Erreur interne'
        );
        console.log(err);
      })
      .finally(() => {
        this.customRouterService.setLoadingState(false);
      });
  }

  public navigateTo(route: string): void {
    this.customRouterService.navigate([route]);
  }

  public onPreviousHistorical(): void {
    if (
      this.recentEvents.length >
      this.currentlyDisplayedRecentEventIndex + 2
    ) {
      this.currentlyDisplayedRecentEventIndex++;
    }
  }

  public onPreviousComing(): void {
    if (
      this.comingEvents.length >
      this.currentlyDisplayedComingEventIndex + 2
    ) {
      this.currentlyDisplayedComingEventIndex++;
    }
  }

  public onPreviousToday(): void {
    if (this.todayEvents.length > this.currentlyDisplayedTodayEventIndex + 2) {
      this.currentlyDisplayedTodayEventIndex++;
    }
  }
  public onNextHistorical(): void {
    if (this.currentlyDisplayedRecentEventIndex > 0) {
      this.currentlyDisplayedRecentEventIndex--;
    }
  }

  public onNextComing(): void {
    if (this.currentlyDisplayedComingEventIndex > 0) {
      this.currentlyDisplayedComingEventIndex--;
    }
  }

  public onNextToday(): void {
    if (this.currentlyDisplayedTodayEventIndex > 0) {
      this.currentlyDisplayedTodayEventIndex--;
    }
  }

  public onCreateEvent(): void {
    const modalRef: NgbModalRef = this.modalService.open(
      AddEventModalComponent
    );
    modalRef.componentInstance.allRooms = this.allRooms;
    modalRef.componentInstance.allGroups = this.allGroups;
    modalRef.componentInstance.allTeams = this.allTeams;
    modalRef.result
      .then((request: PostEventRequest): void => {
        this.eventsBusiness
          .postEvent(request)
          .then(() => {
            this.toastrService.success(
              `L'événement "${request.name}" a bien été ajouté !`,
              'Ajouter un événement'
            );
            this.navigateTo(`events`);
          })
          .catch(err => {
            this.toastrService.error(
              `Erreur lors de l'ajout de l'événement.`,
              'Ajouter un événement'
            );
          });
      })
      .catch((): void => {
        this.toastrService.info(
          `Aucun événement n'a été ajouté.`,
          'Ajouter un événement'
        );
      });
  }
}
