// Internal Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// External Modules
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OAuthModule } from 'angular-oauth2-oidc';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!

// Pages
import { CalendarComponent } from './pages/calendar';
import { LoginComponent } from './pages/login';
import { EventListComponent } from './pages/eventList';
import { HomeComponent } from './pages/home';
import { EventDetailsComponent } from './pages/event-details';
import { AdminComponent } from './pages/admin';
import { SettingsComponent } from './pages/settings/settings.component';
import { ImportEventComponent } from './pages/import-event/import-event.component';

// Components
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginPresenceLogoComponent } from './components/login-presence-logo/login-presence-logo.component';
import { CustomMultipleSelectComponent } from './components/custom-multiple-select';
import { CustomLoaderComponent } from './components/custom-loader/custom-loader.component';
import { AddEventBtnComponent } from './components/addEventButton';
import { CustomCalendarComponent } from './components/custom-calendar-dashboard';

// Modals
import { AddEventModalComponent } from './components/modals/add-event-modal';
import { ConfirmModalComponent } from './components/modals/confirm-modal';
import { AddStudentToPresenceListModalComponent } from './components/modals/add-student-to-presence-list-modal';
import { AddCollaboratorsModalComponent } from './components/modals/add-collaborators-modal';

// Data Access
import { EventsData } from './api/data-access/events.data';
import { GroupsData } from './api/data-access/groups.data';
import { RoomsData } from './api/data-access/rooms.data';
import { TeamsData } from './api/data-access/teams.data';
import { AccountData } from './api/data-access/account.data';
import { StudentsData } from './api/data-access/students.data';
import { AuthentData } from './api/data-access/authent.data';
import { AdminData } from './api/data-access/admin.data';

// Business
import { EventsBusiness } from './api/business/events.business';
import { GroupsBusiness } from './api/business/groups.business';
import { RoomsBusiness } from './api/business/rooms.business';
import { TeamsBusiness } from './api/business/teams.business';
import { AccountBusiness } from './api/business/account.business';
import { StudentsBusiness } from './api/business/students.business';
import { AuthentBusiness } from './api/business/authent.business';
import { AdminBusiness } from './api/business/admin.business';

// Services
import { AuthGuardService } from './services/auth-guard.service';
import { HttpInterceptorService } from './services/httpinterceptor.service';
import { ModalService } from './services/modal.service';
import { EnvironmentService } from './services/environment.service';
import { CustomRouterService } from './services/custom-router.service';
import { QueryHelperService } from './services/queryHelper.service';
import { NgbDateHelperService } from './services/ngbDateHelper.service';
import { DOMAPIExtendedService } from './services/domAPIextended.service';
import { AuthentService } from './services/authent.service';

// Pipes
import { LimitedTextPipe } from './pipes/limited-text.pipe';

// Styles
import '../styles/styles.scss';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CalendarComponent,
    FooterComponent,
    HeaderComponent,
    LoginPresenceLogoComponent,
    EventListComponent,
    CustomMultipleSelectComponent,
    LimitedTextPipe,
    CustomLoaderComponent,
    AddEventBtnComponent,
    AddEventModalComponent,
    ConfirmModalComponent,
    EventDetailsComponent,
    HomeComponent,
    CustomCalendarComponent,
    AdminComponent,
    SettingsComponent,
    ImportEventComponent,
    AddStudentToPresenceListModalComponent,
    AddCollaboratorsModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      maxOpened: 6
    }),
    OAuthModule.forRoot(),
    FullCalendarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    AuthGuardService,
    EnvironmentService,
    ModalService,
    CustomRouterService,
    EventsData,
    EventsBusiness,
    QueryHelperService,
    GroupsData,
    GroupsBusiness,
    RoomsData,
    RoomsBusiness,
    TeamsData,
    TeamsBusiness,
    NgbDateHelperService,
    DOMAPIExtendedService,
    AccountBusiness,
    AccountData,
    StudentsBusiness,
    StudentsData,
    AuthentBusiness,
    AuthentData,
    AuthentService,
    AdminBusiness,
    AdminData
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddEventModalComponent,
    ConfirmModalComponent,
    AddStudentToPresenceListModalComponent,
    AddCollaboratorsModalComponent
  ]
})
export class AppModule { }
