import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { UserInfo } from '../models/userInfo.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';

@Injectable()
export class AuthentService {
  private readonly sessionDataLabel: string = 'sessionData';

  public tokenAvailability$: Subject<boolean> = new Subject();

  constructor() {}

  public setJWT(token: string): void {
    if (token !== undefined && token !== null && token !== '') {
      sessionStorage.setItem(
        this.sessionDataLabel,
        JSON.stringify({
          JWT: token,
          expiresAt: new JwtHelperService().getTokenExpirationDate(token)
        } as SessionData)
      );
      this.tokenAvailability$.next(true);
    }
  }

  public getJWT(): string {
    const context = sessionStorage.getItem(this.sessionDataLabel);
    if (context !== undefined && context !== null) {
      const sessionData: SessionData = JSON.parse(
        sessionStorage.getItem(this.sessionDataLabel)
      );
      return sessionData.JWT;
    } else {
      return undefined;
    }
  }

  public hasValidJWT(): boolean {
    const context = sessionStorage.getItem(this.sessionDataLabel);
    if (context !== undefined && context !== null) {
      const sessionData: SessionData = JSON.parse(
        sessionStorage.getItem(this.sessionDataLabel)
      );
      return (
        sessionData.JWT !== undefined &&
        sessionData.JWT !== null &&
        sessionData.JWT !== '' &&
        moment(sessionData.expiresAt).isAfter(moment(new Date()))
      );
    } else {
      return false;
    }
  }

  public getUserInfo(): UserInfo {
    if (this.hasValidJWT()) {
      const currJWT: string = this.getJWT();
      const decodedToken: UserInfo = new JwtHelperService().decodeToken(
        currJWT
      );
      return Object.assign(new UserInfo(), {
        firstname: decodedToken.firstname,
        lastname: decodedToken.lastname,
        login: decodedToken.login,
        photoUrl: decodedToken.photoUrl,
        role: decodedToken.role
      } as UserInfo);
    } else {
      return undefined;
    }
  }
}

class SessionData {
  public expiresAt: Date;
  public JWT: string;
}
