import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { IdentifiedValue } from 'src/app/models/responses/events/identifiedValue.model';
import { CustomCheckBox } from 'src/app/models/utils/customCheckBox.model';

@Component({
    selector: 'app-custom-multiple-select',
    templateUrl: './custom-multiple-select.component.html',
    styleUrls: ['./custom-multiple-select.component.scss']
})
export class CustomMultipleSelectComponent implements OnInit {

    @Input() public arrayData: Array<IdentifiedValue>;
    @Input() public title: string;
    @Input() public customId: string;
    @Input() public showPanel: boolean;
    @Input() public from: string;
    @Input() public popoverTitle: string;
    @Input() public disabled: boolean;
    @Input() public optionalData: Array<IdentifiedValue>;
    // tslint:disable-next-line: no-output-on-prefix
    @Output() public onModifiedData: EventEmitter<Array<IdentifiedValue>> = new EventEmitter();
    // tslint:disable-next-line: no-output-on-prefix
    @Output() public onTogglePanel: EventEmitter<boolean> = new EventEmitter();

    public checkedData: Array<CustomCheckBox>;

    public constructor(
        private elementRef: ElementRef
    ) {
        this.elementRef = elementRef;
    }

    public ngOnInit() {
        this.initData();
        this.initComponent();
    }

    public toggleChoicesPanel(event): void {
        event.stopPropagation();
        this.showPanel = !this.showPanel;
        this.onTogglePanel.emit(this.showPanel);
        if (this.showPanel) {
            setTimeout(() => {
                document
                .querySelector(`#${this.customId}`)
                .addEventListener('click', (e) => {
                    e.stopPropagation();
                });
            }, 0);
        }
    }

    public getOnlyCheckedData(): CustomCheckBox[] {
        return this.checkedData
            .filter((elem) => elem.checked);
    }

    public onCheckboxChange(): void {
        this.onModifiedData.emit(
            this.checkedData
                .filter((el: CustomCheckBox) => el.checked)
                .map((mappedElem: CustomCheckBox) => Object.assign({}, {
                    id: mappedElem.id,
                    name: mappedElem.name
                }))
        );
    }

    private initComponent(): void {
        if (this.from === 'modal') {
            this.elementRef.nativeElement.style.setProperty('--multiple-checkbox-title-row-marginTop', '5.5px');
            this.elementRef.nativeElement.style.setProperty('--multiple-checkbox-root-marginTop', '0px');
        } else {
            this.elementRef.nativeElement.style.setProperty('--multiple-checkbox-title-row-marginTop', '8px');
            this.elementRef.nativeElement.style.setProperty('--multiple-checkbox-root-margingTop', '7px');
        }
        window.addEventListener('click', (e) => {
            this.showPanel = false;
            this.onTogglePanel.emit(this.showPanel);
        });
    }

    private initData(): void {
        if (this.disabled === undefined) {
            this.disabled = false;
        }
        this.showPanel = false;
        this.checkedData = [];
        if (this.optionalData === undefined || this.optionalData.length === 0) {
            this.arrayData.forEach((el: IdentifiedValue) => {
                const newElem: CustomCheckBox = Object.assign(el, {
                    checked: false
                });
                this.checkedData.push(newElem);
            });
        } else {
            this.arrayData.forEach((el: IdentifiedValue) => {
                const newElem: CustomCheckBox = Object.assign(el, {
                    checked: this.optionalData.some((data) => data.id === el.id)
                });
                this.checkedData.push(newElem);
            });
        }
    }
}
