import { Component, OnInit } from '@angular/core';
import { CustomRouterService } from 'src/app/services/custom-router.service';
import { AccountBusiness } from 'src/app/api/business/account.business';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'settings',
    styleUrls: ['./settings.component.scss'],
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

    public sessionPwd: SessionPwd;

    constructor(
        private customRouterService: CustomRouterService,
        private toastrService: ToastrService,
        private accountBusiness: AccountBusiness
    ) {
        this.customRouterService = customRouterService;
        this.toastrService = toastrService;
        this.accountBusiness = accountBusiness;
    }

    public ngOnInit(): void {
        this.customRouterService.setLoadingState(true);
        this.initComponent();
    }

    public isFormValid(): boolean {
        return this.sessionPwd.password !== undefined 
        && this.sessionPwd.confirm !== undefined 
        && this.isPasswordsEqual() 
        && this.isPasswordANumber() 
        && this.isPassword4Digits();
    }

    public isPasswordANumber(): boolean {
        return !isNaN(Number(this.sessionPwd.password));
    }

    public isPasswordsEqual(): boolean {
        return this.sessionPwd.confirm.localeCompare(this.sessionPwd.password) === 0;
    }

    public isPassword4Digits(): boolean {
        return Number(this.sessionPwd.password) > 999 && Number(this.sessionPwd.password) < 10000;
    }

    public onFormValidation(): void {
        if (this.isFormValid()) {
            this.accountBusiness.updatePinCode(Number(this.sessionPwd.password))
                .then(() => {
                    this.toastrService.success(`Votre code pin a bien été modifié.`, `Modification du code pin`);
                })
                .catch((err: any) => {
                    this.toastrService.error(`Une erreur inconnue lors de la modification vient de se produire.`, `Modification du code pin`);
                    console.log(err);
                });
        }
    }

    private initComponent(): void {
        this.sessionPwd = new SessionPwd();
        this.customRouterService.setLoadingState(false);
        this.customRouterService.setNewComponentName('Paramètres du compte');
    }
}

class SessionPwd {
    public password: string;
    public confirm: string;
}
