import { EnvironmentService } from './../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GetRoomsResponse } from 'src/app/models/responses/rooms/getRoomsResponse.model';

@Injectable()
export class RoomsData {

    private http: HttpClient;
    private environmentService: EnvironmentService;

    constructor(http: HttpClient, environmentService: EnvironmentService) {
        this.http = http;
        this.environmentService = environmentService;
    }

    public async getRooms(): Promise<GetRoomsResponse> {
      return this.http.get<GetRoomsResponse>(`${this.environmentService.environment}/api/rooms`)
      .toPromise()
      .then((res: GetRoomsResponse) => Promise.resolve(res))
      .catch((error: any) => Promise.reject(error));
    }
}
