import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss']
})
export class CustomLoaderComponent implements OnInit {

  @Input() public nbElems: number;
  public rowDataRepresentation: Array<number>;
  public colDataRepresentation: Array<number>;
  public constructor() { }

  ngOnInit() {
    this.getDataRepresentation();
  }

  public getDataRepresentation(): void {
    this.rowDataRepresentation = [];
    this.colDataRepresentation = [];
    for (let i = 0; i < this.nbElems; i++) { this.rowDataRepresentation.push(i); }
    for (let i = 0; i < 9; i++) { this.colDataRepresentation.push(i); }
  }
}
