import { Injectable } from '@angular/core';
import { GroupsData } from '../data-access/groups.data';
import { GetGroupsResponse } from 'src/app/models/responses/groups/getGroupsReponse.model';

@Injectable()
export class GroupsBusiness {

    private groupsData: GroupsData;

    public constructor(groupsData: GroupsData) {
        this.groupsData = groupsData;
    }

    public async getGroups(): Promise<GetGroupsResponse> {
      return this.groupsData.getGroups();
    }
}
