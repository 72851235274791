import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'limitedtext'})
export class LimitedTextPipe implements PipeTransform {
  transform(text: string, length: number): string {
    if (text !== undefined) {
      if (text.length <= length) {
        return text;
      } else {
        return text.slice(0, length - 3) + '...';
      }
    } else {
      return text;
    }
  }
}