import { Injectable } from '@angular/core';
import { AuthentData } from '../data-access/authent.data';
import { GetJWTRequest } from 'src/app/models/requests/authent/getJWTRequest.model';
import { GetJWTResponse } from 'src/app/models/responses/authent/getJWTResponse.model';

@Injectable()
export class AuthentBusiness {

    private authentData: AuthentData;

    public constructor(authentData: AuthentData) {
        this.authentData = authentData;
    }

    public async getJWT(oauthAccessToken: string): Promise<GetJWTResponse> {
      return this.authentData.getJWT({ token: oauthAccessToken } as GetJWTRequest);
    }
}
