import { Injectable } from '@angular/core';
import { StudentsData } from '../data-access/students.data';
import { SearchForStudentsResponse } from 'src/app/models/responses/students/searchForStudentsResponse.model';
import { SearchForStudentsRequest } from 'src/app/models/requests/students/searchForStudentsRequest.model';

@Injectable()
export class StudentsBusiness {

    private studentsData: StudentsData;

    public constructor(studentsData: StudentsData) {
        this.studentsData = studentsData;
    }

    public async searchForStudents(request: SearchForStudentsRequest): Promise<SearchForStudentsResponse> {
        return this.studentsData.searchForStudents(request);
    }
}
