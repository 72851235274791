import { Injectable } from '@angular/core';
import { TeamsData } from '../data-access/teams.data';
import { GetTeamsReponse } from 'src/app/models/responses/teams/getTeamsResponse.model';
import { PostTeamRequest } from 'src/app/models/requests/teams/postTeamRequest.model';
import { Team } from 'src/app/models/responses/teams/team.model';

@Injectable()
export class TeamsBusiness {

    private teamsData: TeamsData;

    public constructor(teamsData: TeamsData) {
        this.teamsData = teamsData;
    }

    public async getTeams(): Promise<GetTeamsReponse> {
      return this.teamsData.getTeams();
    }

    public async postTeam(request: PostTeamRequest): Promise<void> {
      return this.teamsData.postTeam(request);
    }

    public async deleteTeam(teamId: number): Promise<void> {
      return this.teamsData.deleteTeam(teamId);
    }

    public async getTeam(teamId: number): Promise<Team> {
      return this.teamsData.getTeam(teamId);
    }

    public async addUser(teamId: number, userId: number): Promise<void> {
      return this.teamsData.addUser(teamId, userId);
    }
}
