import { EnvironmentService } from '../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivateAdminRequest } from 'src/app/models/requests/admin/activateAdminRequest.model';
import { DeactivateAdminRequest } from 'src/app/models/requests/admin/deactivateAdminRequest.model';
import { SearchForUsersResponse } from 'src/app/models/responses/admin/searchForUsersResponse.model';
import { QueryHelperService } from 'src/app/services/queryHelper.service';
import { SearchForUsersRequest } from 'src/app/models/requests/admin/searchForUsersRequest.model';

@Injectable()
export class AdminData {

    private http: HttpClient;
    private environmentService: EnvironmentService;
    private queryHelperService: QueryHelperService;

    constructor(http: HttpClient, environmentService: EnvironmentService, queryHelperService: QueryHelperService) {
        this.http = http;
        this.environmentService = environmentService;
        this.queryHelperService = queryHelperService;
    }

    public async activateAdmin(request: ActivateAdminRequest): Promise<void> {
        return this.http.post(`${this.environmentService.environment}/api/admin/activate`, request)
            .toPromise()
            .then(() => Promise.resolve())
            .catch((err: any) => Promise.reject(err));
    }

    public async deactivateAdmin(request: DeactivateAdminRequest): Promise<void> {
        return this.http.post(`${this.environmentService.environment}/api/admin/deactivate`, request)
            .toPromise()
            .then(() => Promise.resolve())
            .catch((err: any) => Promise.reject(err));
    }

    public async searchForUsers(request: SearchForUsersRequest): Promise<SearchForUsersResponse> {
        return this.http.get(`${this.environmentService.environment}/api/admin`, {
            params: this.queryHelperService.getQueryFromModel(request)
        })
            .toPromise()
            .then((res: SearchForUsersResponse) => Promise.resolve(res))
            .catch((err: any) => Promise.reject(err));
    }
}
