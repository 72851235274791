import { authConfig } from './config/oauth-epitaconnect.config';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthGuardService } from './services/auth-guard.service';
import { CustomRouterService } from './services/custom-router.service';
import { EnvironmentService } from './services/environment.service';
import { EnvironmentEnum } from './misc/enums/environment.enum';
import { OAuthService, JwksValidationHandler } from 'angular-oauth2-oidc';
import { AuthentService } from './services/authent.service';
import { AuthentBusiness } from './api/business/authent.business';
import { GetJWTResponse } from './models/responses/authent/getJWTResponse.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public hideHeader: boolean;
  public loading: boolean;

  public constructor(
    private router: Router,
    private toastr: ToastrService,
    private authGuards: AuthGuardService,
    private routerService: CustomRouterService,
    private environmentService: EnvironmentService,
    private oauthService: OAuthService,
    private authentService: AuthentService,
    private authentBusiness: AuthentBusiness
  ) {
    this.router = router;
    this.toastr = toastr;
    this.authGuards = authGuards;
    this.routerService = routerService;
    this.environmentService = environmentService;
    this.oauthService = oauthService;
    this.authentService = authentService;
    this.authentBusiness = authentBusiness;
  }

  public ngOnInit(): void {
    this.loading = false;
    this.routerService.loading$.subscribe((isLoading: boolean) => {
      this.loading = isLoading;
    });
    this.hideHeader = false;
    this.environmentService.setEnvironment(EnvironmentEnum.PRODUCTION);
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(event => {
        this.hideHeader = (event.url as string).startsWith('/login');
        const content: HTMLElement = document.querySelector('#content');
        if (this.hideHeader) {
          content.style.marginTop = '7vh';
        } else {
          content.style.marginTop = '12vh';
        }
        this.routerService.previsousUrls.push(event.url);
        this.configure();
      });
  }

  public onDisconnect(): void {
    sessionStorage.clear();
    this.toastr.success(
      'Vous avez bien été déconnecté de la session.',
      'Déconnexion'
    );
    this.routerService.navigate(['login']);
  }

  private configure() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setStorage(sessionStorage);
    this.launchOAuth2Service();
  }

  private launchOAuth2Service(): void {
    if (!this.authentService.hasValidJWT()) {
      if (this.oauthService.hasValidAccessToken()) {
        this.setJWT();
      } else {
        this.oauthService
          .tryLogin()
          .then(() => {
            if (!this.oauthService.getAccessToken()) {
              this.routerService.navigate(['login']);
            } else {
              this.setJWT();
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  private setJWT(): void {
    this.authentBusiness
      .getJWT(this.oauthService.getAccessToken())
      .then((res: GetJWTResponse) => {
        this.authentService.setJWT(res.token);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}
