import { EnvironmentService } from './../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UpdatePinCodeRequest } from 'src/app/models/requests/account/updatePinCode.model';

@Injectable()
export class AccountData {

    private http: HttpClient;
    private environmentService: EnvironmentService;

    constructor(http: HttpClient, environmentService: EnvironmentService) {
        this.http = http;
        this.environmentService = environmentService;
    }

    public async updatePinCode(request: UpdatePinCodeRequest): Promise<void> {
        return this.http.put(`${this.environmentService.environment}/api/admin/password`, request)
        .toPromise()
        .then(() => Promise.resolve())
        .catch((err: any) => Promise.reject(err));
    }
}
