import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Injectable()
export class ModalService {

    constructor(
        private ngbModal: NgbModal
    ) {
        this.ngbModal = ngbModal;
    }

    public open(content, options = {}) {
        return this.ngbModal.open(content, { 
            size: 'lg', 
            backdrop: 'static', 
            centered: true, 
            ...options 
        });
    }
}
