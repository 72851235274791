import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class QueryHelperService {

    constructor() {}

    public getQueryFromModel(model: any): HttpParams {
        let params: HttpParams = new HttpParams();
        for (const key of Object.keys(model)) {
            if (model[key] !== undefined) {
                if (model[key] instanceof Array) {
                    model[key].forEach((el) => {
                        params = params.append(`${key}`, el);
                    });
                } else {
                    params = params.append(key, model[key]);
                }
            }
        }
        return params;
    }
}
