import { EventType } from '../getEventTypes/eventType.model';
import { Team } from '../../teams/team.model';
import { Room } from '../../rooms/room.model';
import { Group } from '../../groups/group.model';
import { IdentifiedValue } from '../identifiedValue.model';
import { Speaker } from '../../speakers/speakers.model';

export class GetEventsSingleEvent extends IdentifiedValue {
    public startDate: string;
    public endDate: string;
    public speakerList: Speaker[];
    public eventType: EventType;
    public teamList: Team[];
    public roomList: Room[];
    public groupList: Group[];

    public startDateCasted: Date;
    public endDateCasted: Date;
}
