import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

    @Input() public title: string;
    @Input() public message: string;
    public disableAction: boolean;

    constructor(
        private activeModal: NgbActiveModal
    ) {
        this.activeModal = activeModal;
    }

    public dismiss(): void {
        this.activeModal.dismiss(false);
    }

    public send(): void {
        this.activeModal.close(true);
    }
}