import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from 'src/app/models/userInfo.model';
import { SearchForUsersResponse } from 'src/app/models/responses/admin/searchForUsersResponse.model';
import { AdminBusiness } from 'src/app/api/business/admin.business';

@Component({
  selector: 'add-collaborators-modal',
  templateUrl: './add-collaborators-modal.component.html',
  styleUrls: ['./add-collaborators-modal.component.scss']
})
export class AddCollaboratorsModalComponent implements OnInit {
  public search: string;
  public userList: UserInfo[];
  public popupUserList: UserInfo[];

  public displayResult: boolean;
  public disableAction: boolean;

  @Input() private initialUserList: UserInfo[];
  private searchActionTrigger$: Subject<string>;
  private displayResult$: Subject<boolean>;
  constructor(
    private activeModal: NgbActiveModal,
    private adminBusiness: AdminBusiness,
    private toastrService: ToastrService
  ) {
    this.activeModal = activeModal;
    this.adminBusiness = adminBusiness;
    this.toastrService = toastrService;
  }

  public ngOnInit(): void {
    this.initComponent();
  }

  public onSearch(search: string): void {
    this.searchActionTrigger$.next(search);
  }

  public dismiss(): void {
    this.activeModal.dismiss(false);
  }

  public send(): void {
    this.activeModal.close(this.userList);
  }

  public onAddUser(selectedUser: UserInfo): void {
    if (
      this.initialUserList !== undefined
    ) {
      if (this.userList.filter((user: UserInfo) => user.id === selectedUser.id).length === 0) {
        this.userList.push(selectedUser);
        this.displayResult$.next(false);
        this.search = '';
      } else {
        this.toastrService.info(
          `L'utilisateur est déjà sélectionné.`,
          `Sélection d'un utilisateur`
        );
      }
    }
  }

  public onDeleteStudent(selectedUser: UserInfo): void {
    this.userList = this.userList.filter(
      (user: UserInfo) => user.id !== selectedUser.id
    );
  }

  private initComponent(): void {
    this.userList = [];
    this.popupUserList = [];
    this.handleDOMAccess();
    this.handleSearchAction();
  }

  private handleDOMAccess(): void {
    this.displayResult$ = new Subject<boolean>();
    this.displayResult$.subscribe((value: boolean) => {
      this.displayResult = value;
      if (this.displayResult) {
        setTimeout(() => {
          document
            .getElementById('custom-user-list__div')
            .addEventListener('click', e => {
              e.stopPropagation();
            });
        }, 0);
      }
    });
    this.displayResult$.next(false);
    window.addEventListener('click', e => this.displayResult$.next(false));
    document.getElementById('searchInput').addEventListener('click', e => {
      if (this.search !== undefined && this.search.length > 2) {
        e.stopPropagation();
        this.displayResult$.next(true);
      }
    });
  }

  private handleSearchAction(): void {
    this.searchActionTrigger$ = new Subject<string>();
    this.searchActionTrigger$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((search: string) => {
        search.length > 2
          ? this.executeSearch(search)
          : this.displayResult$.next(false);
      });
  }

  private executeSearch(search: string): void {
    this.displayResult$.next(false);
    this.adminBusiness
      .searchForUsers(search)
      .then((res: SearchForUsersResponse) => {
        this.popupUserList = res.adminList;
        this.displayResult$.next(true);
      })
      .catch(err => {
        console.log(err);
        this.displayResult$.next(false);
      });
  }
}
