import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EnvironmentService {

    public environment$ = new Subject<string>();
    public environment: string;

    constructor() {
        this.environment$
        .subscribe((environment: string) => {
            this.environment = environment;
        });
    }

    public setEnvironment(environment: string) {
        this.environment$.next(environment);
    }
}
