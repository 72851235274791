import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthentService } from './authent.service';
import { CustomRouterService } from './custom-router.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: CustomRouterService,
    private authentService: AuthentService,
    private toastrService: ToastrService
  ) {
    this.authentService = authentService;
    this.router = router;
    this.toastrService = toastrService;
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.authentService.hasValidJWT()) {
      if (state.url === '/admin') {
        if (this.authentService.getUserInfo().role !== 'superadmin') {
          this.toastrService.warning(
            'Vous devez être administrateur pour accéder à cette page. Contactez un administrateur.',
            'Droits insuffisants'
          );
          this.router.navigate(['dashboard']);
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      this.toastrService.warning(
        'Veuillez vous reconnecter.',
        'Session terminée'
      );
      this.router.navigate(['login']);
      return false;
    }
  }
}
