import { Injectable } from '@angular/core';
import { RoomsData } from '../data-access/rooms.data';
import { GetRoomsResponse } from 'src/app/models/responses/rooms/getRoomsResponse.model';

@Injectable()
export class RoomsBusiness {

    private roomsData: RoomsData;

    public constructor(roomsData: RoomsData) {
        this.roomsData = roomsData;
    }

    public async getRooms(): Promise<GetRoomsResponse> {
      return this.roomsData.getRooms();
    }
}
