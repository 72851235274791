import { EnvironmentService } from '../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetJWTRequest } from 'src/app/models/requests/authent/getJWTRequest.model';
import { GetJWTResponse } from 'src/app/models/responses/authent/getJWTResponse.model';

@Injectable()
export class AuthentData {

    private http: HttpClient;
    private environmentService: EnvironmentService;

    constructor(http: HttpClient, environmentService: EnvironmentService) {
        this.http = http;
        this.environmentService = environmentService;
    }

    public async getJWT(request: GetJWTRequest): Promise<GetJWTResponse> {
      return this.http.post(`${this.environmentService.environment}/api/admin/login`, request)
        .toPromise()
        .then((response: GetJWTResponse) => Promise.resolve(response))
        .catch((err: any) => Promise.reject(err));
    }
}
