import { Injectable } from '@angular/core';

@Injectable()
export class DOMAPIExtendedService {

    constructor() { }

    private multiArgs = (fun: Function): any => (...args: any): Array<any> => args.map((arg: any) => fun(arg));

    public multiQuerySelector(...args: string[]): HTMLElement[] {
        return  this.multiArgs(document.querySelector.bind(document))(args);
    }
}
