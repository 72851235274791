import { EnvironmentService } from './../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GetRecentEventsResponse } from 'src/app/models/responses/events/getRecentEvents/getRecentEvents.model';
import { GetTodayEventsResponse } from 'src/app/models/responses/events/getTodayEvents/getTodayEvents.model';
import { GetEventsRequest } from 'src/app/models/requests/events/getEventsRequest.model';
import { GetEventsResponse } from 'src/app/models/responses/events/getEvents/getEventsResponse.model';
import { QueryHelperService } from 'src/app/services/queryHelper.service';
import { EventType } from 'src/app/models/responses/events/getEventTypes/eventType.model';
import { GetEventTypesResponse } from 'src/app/models/responses/events/getEventTypes/getEventTypesResponse.model';
import { PostEventRequest } from 'src/app/models/requests/events/postEventRequest.model';
import { GetEventsSingleEvent } from 'src/app/models/responses/events/getEvents/getEventsSingleEvent.model';
import { GetPresenceListResponse } from 'src/app/models/responses/events/getPresenceListByEvent/getPresenceListResponse.model';
import { GetComingEventsResponse } from 'src/app/models/responses/events/getComingEvents/getComingEvents.model';
import { GetNbEventByDayResponse } from 'src/app/models/responses/events/getNbEventByDay/getNbEventByDayResponse.model';
import { GetNbEventByDayRequest } from 'src/app/models/requests/events/getNbEventByDayRequest.model';
import { UpdateEventRequest } from 'src/app/models/requests/events/updateEventRequest.model';
import { AddStudentListToPresenceListRequest } from 'src/app/models/requests/events/addStudentListToPresenceListRequest.model';

@Injectable()
export class EventsData {

    private http: HttpClient;
    private environmentService: EnvironmentService;
    private queryHelperService: QueryHelperService;

    constructor(http: HttpClient, environmentService: EnvironmentService, queryHelperService: QueryHelperService) {
        this.http = http;
        this.environmentService = environmentService;
        this.queryHelperService = queryHelperService;
    }

    public async getRecentEvents(): Promise<GetRecentEventsResponse> {
        return this.http.get<GetRecentEventsResponse>(`${this.environmentService.environment}/api/events/recent`)
        .toPromise()
        .then((res: GetRecentEventsResponse) => Promise.resolve(res))
        .catch((error: any) => Promise.reject(error));
    }

    public async getTodayEvents(): Promise<GetTodayEventsResponse> {
        return this.http.get<GetTodayEventsResponse>(`${this.environmentService.environment}/api/events/today`)
        .toPromise()
        .then((response: GetTodayEventsResponse) => Promise.resolve(response))
        .catch((error: any) => Promise.reject(error));
    }

    public async getComingEvents(): Promise<GetComingEventsResponse> {
        return this.http.get<GetComingEventsResponse>(`${this.environmentService.environment}/api/events/coming`)
        .toPromise()
        .then((response: GetComingEventsResponse) => Promise.resolve(response))
        .catch((error: any) => Promise.reject(error));
    }

    public async getEvents(request: GetEventsRequest): Promise<GetEventsResponse> {
        return this.http.get<GetEventsResponse>(`${this.environmentService.environment}/api/events/`, {
            params: this.queryHelperService.getQueryFromModel(request)
        })
        .toPromise()
        .then((response: GetEventsResponse) => Promise.resolve(response))
        .catch((err) => Promise.reject(err));
    }

    public async getEventTypes(): Promise<GetEventTypesResponse> {
        return this.http.get<GetEventTypesResponse>(`${this.environmentService.environment}/api/eventtypes`)
        .toPromise()
        .then((res: GetEventTypesResponse) => Promise.resolve(res))
        .catch((error: any) => Promise.reject(error));
    }

    public async postEvent(request: PostEventRequest): Promise<void> {
        return this.http.post<void>(`${this.environmentService.environment}/api/events`, request)
        .toPromise()
        .then(() => Promise.resolve())
        .catch((error: any) => Promise.reject(error));
    }

    public async deleteEvent(eventId: number): Promise<void> {
        return this.http.delete<void>(`${this.environmentService.environment}/api/events/${eventId}`)
        .toPromise()
        .then(() => Promise.resolve())
        .catch((error: any) => Promise.reject(error));
    }

    public async getEvent(eventId: number): Promise<GetEventsSingleEvent> {
        return this.http.get<GetEventsSingleEvent>(`${this.environmentService.environment}/api/events/${eventId}`)
        .toPromise()
        .then((event: GetEventsSingleEvent) => Promise.resolve(event))
        .catch((error: any) => Promise.reject(error));
    }

    public async getPresenceListByEvent(eventId: number): Promise<GetPresenceListResponse> {
        return this.http.get<GetPresenceListResponse>(`${this.environmentService.environment}/api/events/${eventId}/students`)
        .toPromise()
        .then((event: GetPresenceListResponse) => Promise.resolve(event))
        .catch((error: any) => Promise.reject(error));
    }

    public async getNbEventByDay(request: GetNbEventByDayRequest): Promise<GetNbEventByDayResponse> {
        return this.http.get<GetNbEventByDayResponse>(`${this.environmentService.environment}/api/events/count`, {
            params: this.queryHelperService.getQueryFromModel(request)
        })
        .toPromise()
        .then((response: GetNbEventByDayResponse) => Promise.resolve(response))
        .catch((err) => Promise.reject(err));
    }

    public async removeStudentFromAttendanceList(eventId: number, studentId: number): Promise<void> {
        return this.http.delete<void>(`${this.environmentService.environment}/api/events/${eventId}/students/${studentId}`)
        .toPromise()
        .then(() => Promise.resolve())
        .catch((error: any) => Promise.reject(error));
    }

    public async updateEvent(request: UpdateEventRequest): Promise<void> {
        return this.http.put<void>(`${this.environmentService.environment}/api/events`, request)
        .toPromise()
        .then(() => Promise.resolve())
        .catch((error: any) => Promise.reject(error));
    }

    public async addStudentListToAttendanceList(eventId: number, request: AddStudentListToPresenceListRequest): Promise<void> {
        return this.http.post<void>(`${this.environmentService.environment}/api/events/${eventId}/students`, request)
        .toPromise()
        .then(() => Promise.resolve())
        .catch((error: any) => Promise.reject(error));
    }
}
