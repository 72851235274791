import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Group } from 'src/app/models/responses/groups/group.model';
import { Student } from 'src/app/models/responses/students/student.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { StudentsBusiness } from 'src/app/api/business/students.business';
import { SearchForStudentsResponse } from 'src/app/models/responses/students/searchForStudentsResponse.model';
import { SearchForStudentsRequest } from 'src/app/models/requests/students/searchForStudentsRequest.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'add-student-to-presence-list-modal',
  templateUrl: './add-student-to-presence-list-modal.component.html',
  styleUrls: ['./add-student-to-presence-list-modal.component.scss']
})
export class AddStudentToPresenceListModalComponent implements OnInit {
  @Input() public studentsGroupList: Group[];

  public search: string;
  public studentList: Student[];
  public popupStudentList: Student[];

  public displayResult: boolean;
  public disableAction: boolean;

  @Input() private initialStudentList: Student[];
  private searchActionTrigger$: Subject<string>;
  private displayResult$: Subject<boolean>;
  constructor(
    private activeModal: NgbActiveModal,
    private studentsBusiness: StudentsBusiness,
    private toastrService: ToastrService
  ) {
    this.activeModal = activeModal;
    this.studentsBusiness = studentsBusiness;
    this.toastrService = toastrService;
  }

  public ngOnInit(): void {
    this.initComponent();
  }

  public stringifyGroupsOfStudent(student: Student): string {
    return student.groupList.map((group: Group) => group.name).join(', ');
  }

  public getImgSrcFromStudent(student: Student): string {
    return `https://photos.cri.epita.fr/thumb/${student.login}`;
  }

  public onSearch(search: string): void {
    this.searchActionTrigger$.next(search);
  }

  public dismiss(): void {
    this.activeModal.dismiss(false);
  }

  public send(): void {
    this.activeModal.close(this.studentList);
  }

  public onAddStudent(student: Student): void {
    if (
      this.initialStudentList !== undefined &&
      this.initialStudentList.filter((stud: Student) => stud.id === student.id).length === 0
    ) {
      if (this.studentList.filter((stud: Student) => stud.id === student.id).length === 0) {
        this.studentList.push(student);
        this.displayResult$.next(false);
        this.search = '';
      } else {
        this.toastrService.info(
          `L'étudiant est déjà sélectionné.`,
          `Sélection d'un étudiant`
        );
      }
    } else {
      this.toastrService.info(
        `La présence de l'étudiant est déjà enregistrée.`,
        `Sélection d'un étudiant`
      );
    }
  }

  public onDeleteStudent(student: Student): void {
    this.studentList = this.studentList.filter(
      (stud: Student) => stud.id !== student.id
    );
  }

  private initComponent(): void {
    this.studentList = [];
    this.popupStudentList = [];
    this.handleDOMAccess();
    this.handleSearchAction();
  }

  private handleDOMAccess(): void {
    this.displayResult$ = new Subject<boolean>();
    this.displayResult$.subscribe((value: boolean) => {
      this.displayResult = value;
      if (this.displayResult) {
        setTimeout(() => {
          document
            .getElementById('custom-student-list__div')
            .addEventListener('click', e => {
              e.stopPropagation();
            });
        }, 0);
      }
    });
    this.displayResult$.next(false);
    window.addEventListener('click', e => this.displayResult$.next(false));
    document.getElementById('searchInput').addEventListener('click', e => {
      if (this.search !== undefined && this.search.length > 2) {
        e.stopPropagation();
        this.displayResult$.next(true);
      }
    });
  }

  private handleSearchAction(): void {
    this.searchActionTrigger$ = new Subject<string>();
    this.searchActionTrigger$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((search: string) => {
        search.length > 2
          ? this.executeSearch(search)
          : this.displayResult$.next(false);
      });
  }

  private executeSearch(search: string): void {
    this.displayResult$.next(false);
    const request = {
      search,
      groupId: this.studentsGroupList.map((group: Group) => group.id)
    } as SearchForStudentsRequest;
    this.studentsBusiness
      .searchForStudents(request)
      .then((res: SearchForStudentsResponse) => {
        this.popupStudentList = res.studentList;
        this.displayResult$.next(true);
      })
      .catch(err => {
        console.log(err);
        this.displayResult$.next(false);
      });
  }
}
