import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, PluginDef } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { EventsBusiness } from 'src/app/api/business/events.business';
import { GetNbEventByDayResponse } from 'src/app/models/responses/events/getNbEventByDay/getNbEventByDayResponse.model';
import { NbEventByDay } from 'src/app/models/responses/events/getNbEventByDay/nbEventByDay.model';
import * as moment from 'moment';

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar-dashboard.component.html',
  styleUrls: ['./custom-calendar-dashboard.component.scss']
})
export class CustomCalendarComponent implements OnInit {

  public calendarPlugins: PluginDef[];
  public calendarEvents: EventInput[];

  public currentMonthDisplayed: Date;

  public constructor(
    private eventsBusiness: EventsBusiness,
  ) {
    this.eventsBusiness = eventsBusiness;
  }

  public ngOnInit(): void {
    this.initComponent();
    this.loadDate();
  }

  private initComponent(): void {
    this.currentMonthDisplayed = new Date();
    this.calendarPlugins = [dayGridPlugin, interactionPlugin];
    this.calendarEvents = [];
    this.enableCalendarDOMAccess();
  }

  private enableCalendarDOMAccess(): void {
    let todayButton: HTMLButtonElement = null;
    let prevButton: HTMLButtonElement = null;
    let nextButton: HTMLButtonElement = null;
    const loopID = setInterval(() => {
      todayButton = document.querySelector('button.fc-today-button');
      prevButton = document.querySelector('button[aria-label="prev"]');
      nextButton = document.querySelector('button[aria-label="next"]');
      if (todayButton !== undefined && todayButton !== null
        && prevButton !== undefined && prevButton !== null
        && nextButton !== undefined && nextButton !== null) {
        todayButton.addEventListener('click', (e) => { this.onTodayClick(); });
        prevButton.addEventListener('click', (e) => { this.onPrevClick(); });
        nextButton.addEventListener('click', (e) => { this.onNextClick(); });
        clearInterval(loopID);
      }
    }, 5000);
  }

  private onTodayClick(): void {
    this.currentMonthDisplayed = new Date();
    this.loadDate();
  }

  private onPrevClick(): void {
    this.currentMonthDisplayed = moment(this.currentMonthDisplayed).startOf('month').subtract('1', 'months').toDate();
    this.loadDate();
  }

  private onNextClick(): void {
    this.currentMonthDisplayed = moment(this.currentMonthDisplayed).startOf('month').add('1', 'months').toDate();
    this.loadDate();
  }

  private loadDate(): void {
    this.eventsBusiness.getNbEventByDay(this.currentMonthDisplayed)
      .then((res: GetNbEventByDayResponse) => {
        this.calendarEvents = res.countEventList.reduce((acc: EventInput[], curr: NbEventByDay) => {
          return [...acc, {
            title: `${curr.nbEvents > 99 ? '99+' : curr.nbEvents}`,
            start: curr.date,
            allDay: true,
            textColor: 'white'
          }];
        }, []);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  public handleDateClick(arg: any) {
    console.log(arg);
  }

  public handleEventClick(arg: any) {
    console.log(arg);
  }

  private modifyTitle(eventIndex: number, newTitle: string) {
    let calendarEvents = this.calendarEvents.slice();
    let singleEvent = Object.assign({}, calendarEvents[eventIndex]);
    singleEvent.title = newTitle;
    calendarEvents[eventIndex] = singleEvent;
    this.calendarEvents = calendarEvents;
  }
}
