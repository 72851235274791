import { EnvironmentService } from './../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GetTeamsReponse } from 'src/app/models/responses/teams/getTeamsResponse.model';
import { PostTeamRequest } from 'src/app/models/requests/teams/postTeamRequest.model';
import { Team } from 'src/app/models/responses/teams/team.model';

@Injectable()
export class TeamsData {

    private http: HttpClient;
    private environmentService: EnvironmentService;

    constructor(http: HttpClient, environmentService: EnvironmentService) {
        this.http = http;
        this.environmentService = environmentService;
    }

    public async getTeams(): Promise<GetTeamsReponse> {
      return this.http.get<GetTeamsReponse>(`${this.environmentService.environment}/api/teams`)
      .toPromise()
      .then((res: GetTeamsReponse) => Promise.resolve(res))
      .catch((error: any) => Promise.reject(error));
    }

    public async postTeam(request: PostTeamRequest): Promise<void> {
      return this.http.post<void>(`${this.environmentService.environment}/api/teams`, request)
      .toPromise()
      .then(() => Promise.resolve())
      .catch((error: any) => Promise.reject(error));
    }

    public async deleteTeam(teamId: number): Promise<void> {
      return this.http.delete<void>(`${this.environmentService.environment}/api/teams/${teamId}`)
      .toPromise()
      .then(() => Promise.resolve())
      .catch((error: any) => Promise.reject(error));
    }

    public async getTeam(teamId: number): Promise<Team> {
      return this.http.get<Team>(`${this.environmentService.environment}/api/teams/${teamId}`)
      .toPromise()
      .then((res: Team) => Promise.resolve(res))
      .catch((error: any) => Promise.reject(error));
    }

    public async addUser(teamId: number, userId: number): Promise<void> {
      return this.http.post<void>(`${this.environmentService.environment}/api/teams/${teamId}/users/${userId}`, {})
      .toPromise()
      .then(() => Promise.resolve())
      .catch((error: any) => Promise.reject(error));
    }
}
