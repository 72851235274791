import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomRouterService } from 'src/app/services/custom-router.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { AuthentService } from 'src/app/services/authent.service';
import { UserInfo } from 'src/app/models/userInfo.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() public onDisconnect: EventEmitter<void> = new EventEmitter();

  public currentComponentName: string;
  public currentUser: UserInfo;

  public constructor(
    private oauthService: OAuthService,
    private customRouterService: CustomRouterService,
    private authentService: AuthentService,
    private router: Router
  ) {
    this.customRouterService = customRouterService;
    this.oauthService = oauthService;
    this.authentService = authentService;
    this.router = router;
  }

  public ngOnInit() {
    this.listenForRouteChange();
    this.listenForUserInfo();
  }

  public logoff(): void {
    this.oauthService.logOut();
  }

  public onLogoClick(): void {
    if (this.router.url !== '/dashboard') {
      this.customRouterService.navigate(['dashboard']);
    }
  }

  public disconnect(): void {
    this.onDisconnect.emit();
  }

  private listenForRouteChange(): void {
    this.customRouterService.currentPrettyNameComponent$.subscribe((name: string) => {
      this.currentComponentName = name;
    });
  }

  private listenForUserInfo(): void {
    if (this.authentService.hasValidJWT()) {
      this.currentUser = this.authentService.getUserInfo();
    } else {
      this.authentService.tokenAvailability$.subscribe((isAvailable: boolean) => {
        if (isAvailable) {
          this.currentUser = this.authentService.getUserInfo();
        }
      });
    }
  }
}
