import { Component, OnInit } from '@angular/core';
import { CustomRouterService } from 'src/app/services/custom-router.service';
import { TeamsBusiness } from 'src/app/api/business/teams.business';
import { PostTeamRequest } from 'src/app/models/requests/teams/postTeamRequest.model';
import { ToastrService } from 'ngx-toastr';
import { GetTeamsReponse } from 'src/app/models/responses/teams/getTeamsResponse.model';
import { Team } from 'src/app/models/responses/teams/team.model';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal';
import { AdminBusiness } from 'src/app/api/business/admin.business';
import { HttpErrorResponse } from '@angular/common/http';
import { UserInfo } from 'src/app/models/userInfo.model';
import { AddCollaboratorsModalComponent } from 'src/app/components/modals/add-collaborators-modal';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin',
  styleUrls: ['./admin.component.scss'],
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {
  public disableAction: boolean;

  public newUserLogin: string;
  public newUserLoginConfirm: string;
  public deactivateUserLogin: string;
  public deactivateUserLoginConfirm: string;
  public newTeamName: string;
  public isCreatingTeam: boolean;

  public allTeams: Team[];

  public currentlyDisplayedTeam: Team;

  constructor(
    private customRouterService: CustomRouterService,
    private toastrService: ToastrService,
    private modalService: ModalService,
    private teamsBusiness: TeamsBusiness,
    private adminBusiness: AdminBusiness
  ) {
    this.customRouterService = customRouterService;
    this.toastrService = toastrService;
    this.modalService = modalService;
    this.teamsBusiness = teamsBusiness;
    this.adminBusiness = adminBusiness;
  }

  public ngOnInit(): void {
    this.customRouterService.setLoadingState(true);
    this.initComponent();
    this.loadData();
  }

  public onDeleteTeam(team: Team): void {
    if (team !== undefined) {
      const modalRef = this.modalService.open(ConfirmModalComponent);
      modalRef.componentInstance.title = `Supprimer une équipe`;
      modalRef.componentInstance.message = `Êtes-vous sûr de vouloir supprimer l'équipe ${team.name} de la liste des équipes ?<br>
                Cette action est irrévocable.`;
      this.disableAction = true;
      modalRef.result
        .then(() => {
          this.teamsBusiness
            .deleteTeam(team.id)
            .then(() => {
              this.toastrService.success(
                `L'équipe ${team.name} a bien été enlevée de la liste.`,
                'Supprimer une équipe'
              );
              this.loadData();
            })
            .catch((err: any) => {
              this.toastrService.error(
                'Impossible de supprimer cette équipe.',
                'Erreur interne'
              );
              console.log(err);
              this.disableAction = false;
            });
        })
        .catch((err: any) => {
          this.toastrService.info(
            `Aucun accès à Présences n'a été donné au compte CRI ayant pour login ${this.newUserLogin}.`,
            `Droits d'accès pour un utilisateur`
          );
          console.log(err);
          this.disableAction = false;
        });
    }
  }

  public onCreateTeam(): void {
    if (this.newTeamName !== undefined && this.newTeamName !== '') {
      this.disableAction = true;
      this.teamsBusiness
        .postTeam({
          name: this.newTeamName,
          userList: []
        } as PostTeamRequest)
        .then(() => {
          this.loadData();
        })
        .catch(err => {
          console.log(err);
          this.toastrService.error(
            `Une erreur est survenue lors de la création de l'équipe.`,
            'Créer une équipe'
          );
          this.disableAction = false;
        });
    }
  }

  public onAddTeamUsers(): void {
    const modalRef: NgbModalRef = this.modalService.open(
      AddCollaboratorsModalComponent
    );
    modalRef.componentInstance.initialUserList = this.currentlyDisplayedTeam.userList;
    modalRef.result
      .then((userList: UserInfo[]): void => {
        const promises: Promise<void>[] = userList.map((user: UserInfo) => this.teamsBusiness.addUser(this.currentlyDisplayedTeam.id, user.id));
        Promise.all(promises)
          .then(() => {
            this.toastrService.success(
              `La liste des collaborateurs de l'équipe ${this.currentlyDisplayedTeam.name} a bien été mise à jour.`,
              `Ajouter des collaborateurs à l'équipe`
            );
            this.onLoadTeamComposition(this.currentlyDisplayedTeam);
          })
          .catch((err: HttpErrorResponse) => {
            this.toastrService.error(
              `La liste des étudiants n'a pas pu être mise à jour.`,
              'Ajouter des étudiants à la liste de présence'
            );
          });
      })
      .catch((): void => {
        this.toastrService.info(
          "Aucun étudiant n'a été ajouté à la liste de présence."
        );
      });
  }

  public areBothNewLoginValid(): boolean {
    return (
      this.newUserLogin !== undefined &&
      this.newUserLoginConfirm !== undefined &&
      this.newUserLogin.length > 0 &&
      this.newUserLoginConfirm.length > 0 &&
      this.newUserLogin.localeCompare(this.newUserLoginConfirm) === 0
    );
  }

  public isNewUserLoginFormInited(): boolean {
    return (
      this.newUserLogin !== undefined &&
      this.newUserLogin.length > 0 &&
      this.newUserLoginConfirm !== undefined &&
      this.newUserLoginConfirm.length > 0
    );
  }

  public areBothDeactivateLoginValid(): boolean {
    return (
      this.deactivateUserLogin !== undefined &&
      this.deactivateUserLoginConfirm !== undefined &&
      this.deactivateUserLogin.length > 0 &&
      this.deactivateUserLoginConfirm.length > 0 &&
      this.deactivateUserLogin.localeCompare(
        this.deactivateUserLoginConfirm
      ) === 0
    );
  }

  public isDeactivateUserLoginFormInited(): boolean {
    return (
      this.deactivateUserLogin !== undefined &&
      this.deactivateUserLogin.length > 0 &&
      this.deactivateUserLoginConfirm !== undefined &&
      this.deactivateUserLoginConfirm.length > 0
    );
  }

  public onSubmitNewUser(): void {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = `Donner l'accès à Présences à un compte CRI`;
    modalRef.componentInstance.message = `Êtes-vous sûr de vouloir donner l'accès à Présences en tant qu'administrateur au compte CRI ayant pour login <u>${this.newUserLogin}</u> ?<br>
        Vous pourrez révoquer ses droits à tout moment par la suite.`;
    this.disableAction = true;
    modalRef.result
      .then(() => {
        this.adminBusiness
          .activateAdmin(this.newUserLogin)
          .then(() => {
            this.toastrService.success(
              `L'accès à Présences en tant qu'administrateur a bien été donné au compte CRI ayant pour login ${this.newUserLogin}.`,
              `Droits d'accès pour un utilisateur`
            );
            this.disableAction = false;
          })
          .catch((err: HttpErrorResponse) => {
            console.log(err);
            if (err.status === 409) {
              this.toastrService.warning(
                `L'utilisateur ${this.newUserLogin} a déjà un compte Présences actif. Aucun changement n'a donc été apporté.`,
                `Droits d'accès pour un utilisateur`
              );
            } else {
              this.toastrService.error(
                `Une erreur est survenue lors de l'activation du compte Présences de l'utilisateur CRI ${this.newUserLogin}.`,
                'Erreur interne'
              );
            }
            this.disableAction = false;
          });
      })
      .catch(err => {
        this.toastrService.info(
          `Aucun accès à Présences n'a été donné au compte CRI ayant pour login ${this.newUserLogin}.`,
          `Droits d'accès pour un utilisateur`
        );
        this.disableAction = false;
      });
  }

  public onDeactivateUser(): void {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = `Désactiver un compte Présences`;
    modalRef.componentInstance.message = `Êtes-vous sûr de vouloir révoquer l'accès à Présences en tant qu'administrateur au compte CRI ayant pour login <u>${this.deactivateUserLogin}</u> ?`;
    this.disableAction = true;
    modalRef.result
      .then(() => {
        this.adminBusiness
          .deactivateAdmin(this.deactivateUserLogin)
          .then(() => {
            this.toastrService.success(
              `L'accès à Présences en tant qu'administrateur a bien été révoqué pour l'utilisateur ${this.deactivateUserLogin}.`,
              `Désactiver un compte Présences`
            );
            this.disableAction = false;
          })
          .catch((err: HttpErrorResponse) => {
            console.log(err);
            if (err.status === 409) {
              this.toastrService.warning(
                `L'utilisateur ${this.deactivateUserLogin} n'existe pas. Aucun changement n'a donc été apporté.`,
                `Droits d'accès pour un utilisateur`
              );
            } else {
              this.toastrService.error(
                `Une erreur est survenue lors de la désactivation du compte Présences de l'utilisateur CRI ${this.deactivateUserLogin}.`,
                'Erreur interne'
              );
            }
            this.disableAction = false;
          });
      })
      .catch(err => {
        this.toastrService.info(
          `Aucun compte n'a été désactivé.`,
          `Désactiver un compte Présences`
        );
        this.disableAction = false;
      });
  }

  public onCreateTeamBtn(): void {
    this.isCreatingTeam = !this.isCreatingTeam;
    this.newTeamName = '';
  }

  public onLoadTeamComposition(team: Team, reloading?: boolean): void {
    if (
      this.currentlyDisplayedTeam !== null &&
      this.currentlyDisplayedTeam !== undefined &&
      (reloading === undefined || reloading === false) &&
      this.currentlyDisplayedTeam.id === team.id
    ) {
      this.currentlyDisplayedTeam = null;
    } else {
      this.teamsBusiness
        .getTeam(team.id)
        .then((fullTeam: Team) => {
          this.currentlyDisplayedTeam = fullTeam;
        })
        .catch(err => {
          console.log(err);
          this.toastrService.error(
            `Une erreur est survenue lors de la récupération des informations de l'équipe ${team.name}.`,
            'Erreur interne'
          );
        });
    }
  }

  public onRemoveUserFromCurrentlyDisplayedTeam(user: UserInfo): void { }

  private initComponent(): void {
    this.isCreatingTeam = false;
    this.allTeams = [];
    this.currentlyDisplayedTeam = null;
    this.customRouterService.setLoadingState(false);
    this.customRouterService.setNewComponentName('Espace administrateur');
  }

  private loadData(): void {
    this.disableAction = true;
    this.teamsBusiness
      .getTeams()
      .then((res: GetTeamsReponse) => {
        this.allTeams = res.teamList;
        this.disableAction = false;
      })
      .catch(err => {
        console.log(err);
        this.toastrService.error(
          'Impossible de récupérer les données des équipes depuis le serveur.',
          'Erreur interne'
        );
        this.disableAction = false;
      });
  }
}
