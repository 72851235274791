import { EnvironmentService } from './../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GetGroupsResponse } from 'src/app/models/responses/groups/getGroupsReponse.model';

@Injectable()
export class GroupsData {

    private http: HttpClient;
    private environmentService: EnvironmentService;

    constructor(http: HttpClient, environmentService: EnvironmentService) {
        this.http = http;
        this.environmentService = environmentService;
    }

    public async getGroups(): Promise<GetGroupsResponse> {
      return this.http.get<GetGroupsResponse>(`${this.environmentService.environment}/api/groups`)
      .toPromise()
      .then((res: GetGroupsResponse) => Promise.resolve(res))
      .catch((error: any) => Promise.reject(error));
    }
}
