import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthentService } from './authent.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private authentService: AuthentService,
    private router: Router
  ) {
    this.authentService = authentService;
    this.router = router;
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      headers: request.headers
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${this.authentService.getJWT()}`)
    });
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>): any => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any): any => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['']);
            }
          }
        }
      )
    );
  }
}
