import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomAnimationService } from 'src/app/services/custom-animation.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { CustomRouterService } from 'src/app/services/custom-router.service';
import { AuthentService } from 'src/app/services/authent.service';
import { GetJWTResponse } from 'src/app/models/responses/authent/getJWTResponse.model';
import { AuthentBusiness } from 'src/app/api/business/authent.business';

@Component({
  selector: 'login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public disableAction: boolean;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private animationService: CustomAnimationService,
    private authentBusiness: AuthentBusiness,
    private authentService: AuthentService,
    private oauthService: OAuthService,
    private customRouterService: CustomRouterService
  ) {
    this.router = router;
    this.toastr = toastr;
    this.animationService = animationService;
    this.oauthService = oauthService;
    this.customRouterService = customRouterService;
    this.authentService = authentService;
    this.authentBusiness = authentBusiness;
  }

  public ngOnInit(): void {
    this.customRouterService.setLoadingState(true);
    this.initComponent();
    this.animateOnInit();
    this.disableAction = false;
  }

  public onLogin(): void {
    this.disableAction = true;
    this.oauthService.initImplicitFlow();
  }

  private animateOnInit(): void {
    const connectBtn: HTMLButtonElement = document.querySelector(
      'button#connectBtn'
    );
    const container: HTMLDivElement = document.querySelector('div.container');

    connectBtn.style.opacity = '0';

    this.animationService.animateOpacity(container, 0);
    this.animationService.animateOpacityTranslateFromBottom(connectBtn, 2500);
  }

  private initComponent(): void {
    if (this.authentService.hasValidJWT()) {
      this.customRouterService.navigate(['dashboard']);
      this.toastr.success(
        'Bienvenue dans votre espace Présences.',
        'Connexion réussie'
      );
    } else {
      if (this.oauthService.hasValidAccessToken()) {
        this.authentBusiness
          .getJWT(this.oauthService.getAccessToken())
          .then((res: GetJWTResponse) => {
            this.authentService.setJWT(res.token);
            this.customRouterService.navigate(['dashboard']);
            this.toastr.success(
              'Bienvenue dans votre espace Présences.',
              'Connexion réussie'
            );
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        this.customRouterService.setLoadingState(false);
        this.customRouterService.setNewComponentName('Connexion');
      }
    }
  }
}
