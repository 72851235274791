import { Component, OnInit } from '@angular/core';
import { CustomRouterService } from 'src/app/services/custom-router.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'import-event',
  styleUrls: ['./import-event.component.scss'],
  templateUrl: './import-event.component.html'
})
export class ImportEventComponent implements OnInit {
  public uploadedEvents: any[];
  public uploading: boolean;

  constructor(
    private customRouterService: CustomRouterService,
    private toastrService: ToastrService
  ) {
    this.toastrService = toastrService;
    this.customRouterService = customRouterService;
  }

  public ngOnInit(): void {
    this.customRouterService.setLoadingState(true);
    this.initComponent();
  }

  public onUploadEventsFile(): void {
    const input: HTMLInputElement = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.addEventListener('change', (e: Event) => {
      const file: File = (e.target as HTMLInputElement).files.item(0);
      if (file.name.length >= 4 && file.name.substr(file.name.length - 4, 4) === '.csv') {
        this.uploading = true;
        const reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.addEventListener('load', (ev: any) => {
          const content = ev.target.result as string;
          console.log(content);
        });
      } else {
        this.toastrService.error(
          'Seuls les fichier au format .csv sont autorisés. Téléchargez un template pour voir un exemple.',
          'Format de fichier incorrect'
        );
      }
    });
    document.body.appendChild(input);
    input.click();
    document.body.removeChild(input);
  }

  private initComponent(): void {
    this.uploadedEvents = [];
    this.uploading = false;
    this.customRouterService.setLoadingState(false);
    this.customRouterService.setNewComponentName(`Import d'événements`);
  }
}
