import { EnvironmentService } from '../../services/environment.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryHelperService } from 'src/app/services/queryHelper.service';
import { SearchForStudentsResponse } from 'src/app/models/responses/students/searchForStudentsResponse.model';
import { SearchForStudentsRequest } from 'src/app/models/requests/students/searchForStudentsRequest.model';

@Injectable()
export class StudentsData {

    private http: HttpClient;
    private environmentService: EnvironmentService;
    private queryHelperService: QueryHelperService;

    constructor(http: HttpClient, environmentService: EnvironmentService, queryHelperService: QueryHelperService) {
        this.http = http;
        this.environmentService = environmentService;
        this.queryHelperService = queryHelperService;
    }

    public async searchForStudents(request: SearchForStudentsRequest): Promise<SearchForStudentsResponse> {
        return this.http.get<SearchForStudentsResponse>(`${this.environmentService.environment}/api/students/`, {
            params: this.queryHelperService.getQueryFromModel(request)
        })
        .toPromise()
        .then((response: SearchForStudentsResponse) => Promise.resolve(response))
        .catch((err) => Promise.reject(err));
    }
}
