import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomAnimationService {

  constructor() { }

  public animateOpacity(element: HTMLElement, delay: number): Animation {
    return element.animate([
      { opacity: '0' },
      { opacity: '1' },
    ], {
      easing: 'ease-in-out',
      duration: 400,
      iterations: 1,
      fill: 'forwards',
      delay
    });
  }

  public animateScaling(element: HTMLElement, delay: number): Animation {
    return element.animate([
      { transform: 'scale(0.7)'},
      { transform: 'scale(1)'}
    ], {
      easing: 'ease-in-out',
      duration: 400,
      iterations: 1,
      fill: 'forwards',
      delay
    });
  }

  public animateOpacityTranslateFromBottom(element: HTMLElement, delay: number): Animation {
    return element.animate([
      { transform: 'translateY(25px)', opacity: 0},
      { transform: 'translateY(0px)', opacity: 1}
    ], {
      easing: 'ease-in-out',
      duration: 1000,
      iterations: 1,
      fill: 'forwards',
      delay
    });
  }
}
