import { Component, OnInit } from '@angular/core';
import { CustomRouterService } from 'src/app/services/custom-router.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { EventsBusiness } from 'src/app/api/business/events.business';
import { FullEvent } from 'src/app/models/responses/events/getEvent/fullEvent.model';
import { Room } from 'src/app/models/responses/rooms/room.model';
import { Group } from 'src/app/models/responses/groups/group.model';
import { RoomsBusiness } from 'src/app/api/business/rooms.business';
import { GroupsBusiness } from 'src/app/api/business/groups.business';
import { GetRoomsResponse } from 'src/app/models/responses/rooms/getRoomsResponse.model';
import { GetGroupsResponse } from 'src/app/models/responses/groups/getGroupsReponse.model';
import { MultipleSelectPanelHandler } from 'src/app/models/multipleSelectPanelHandler.model';
import { GetEventTypesResponse } from 'src/app/models/responses/events/getEventTypes/getEventTypesResponse.model';
import { EventType } from 'src/app/models/responses/events/getEventTypes/eventType.model';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgbDateHelperService } from 'src/app/services/ngbDateHelper.service';
import { CustomAnimationService } from 'src/app/services/custom-animation.service';
import { Student } from 'src/app/models/responses/students/student.model';
import { Presence } from 'src/app/models/responses/events/getPresenceListByEvent/presence.model';
import { ToastrService } from 'ngx-toastr';
import { ModalService } from 'src/app/services/modal.service';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal';
import { UpdateEventRequest } from 'src/app/models/requests/events/updateEventRequest.model';
import { Team } from 'src/app/models/responses/teams/team.model';
import { TeamsBusiness } from 'src/app/api/business/teams.business';
import { GetTeamsReponse } from 'src/app/models/responses/teams/getTeamsResponse.model';
import { AddStudentToPresenceListModalComponent } from 'src/app/components/modals/add-student-to-presence-list-modal';
import { AddStudentListToPresenceListRequest } from 'src/app/models/requests/events/addStudentListToPresenceListRequest.model';
import { MinifiedPresence } from 'src/app/models/requests/events/minifiedPresence.model';

@Component({
  selector: 'event-details',
  styleUrls: ['./event-details.component.scss'],
  templateUrl: './event-details.component.html'
})
export class EventDetailsComponent implements OnInit {
  public loading = false;

  public allRooms: Room[];
  public allGroups: Group[];
  public allTeams: Team[];
  public allTypes: EventType[];

  public componentReady = false;
  public event: FullEvent;
  public multipleSelectPanelHandler: MultipleSelectPanelHandler;

  public startDatePicked: NgbDateStruct;
  public startHourFromSelect: string;
  public startMinFromSelect: string;
  public endDatePicked: NgbDateStruct;
  public endHourFromSelect: string;
  public endMinFromSelect: string;

  constructor(
    private customRouterService: CustomRouterService,
    private activatedRoute: ActivatedRoute,
    private eventsBusiness: EventsBusiness,
    private roomsBusiness: RoomsBusiness,
    private groupsBusiness: GroupsBusiness,
    private teamsBusiness: TeamsBusiness,
    private ngbDateHelperService: NgbDateHelperService,
    private animationService: CustomAnimationService,
    private modalService: ModalService,
    private toastrService: ToastrService
  ) {
    this.customRouterService = customRouterService;
    this.activatedRoute = activatedRoute;
    this.eventsBusiness = eventsBusiness;
    this.roomsBusiness = roomsBusiness;
    this.groupsBusiness = groupsBusiness;
    this.ngbDateHelperService = ngbDateHelperService;
    this.animationService = animationService;
    this.modalService = modalService;
    this.toastrService = toastrService;
  }

  public ngOnInit(): void {
    this.customRouterService.setLoadingState(true);
    this.initComponent();
  }

  public stringifyGroupsOfStudent(student: Student): string {
    return student.groupList.map((group: Group) => group.name).join(', ');
  }

  public onDeleteAttendance(presence: Presence): void {
    const modalRef: NgbModalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = `Confirmer la suppression de ${presence.student.login} de la liste de présences`;
    modalRef.componentInstance.message = `Êtes-vous sûr de vouloir supprimer ${presence.student.login}
        de la liste de présence de l'événement ?`;
    modalRef.result
      .then((): void => {
        this.loading = true;
        this.eventsBusiness
          .removeStudentFromAttendanceList(
            this.event.infos.id,
            presence.student.id
          )
          .then(() => {
            this.toastrService.success(
              `L'étudiant ${presence.student.login} a bien été supprimé.`,
              `Suppression réussie`
            );
            this.loading = false;
            this.reloadEvent(this.event.infos.id);
          })
          .catch(err => {
            console.log(err);
            this.toastrService.error(
              `Impossible de supprimer l'étudiant ${presence.student.login}.`,
              `Erreur lors de la suppression`
            );
            this.loading = false;
          });
      })
      .catch((): void => {
        this.toastrService.info(
          `L'étudiant ${presence.student.login} n'a pas été supprimé de la liste de présence.`,
          `Suppression annulée`
        );
      });
  }

  public getImgSrcFromStudent(student: Student): string {
    if (student !== undefined && student !== null) {
      return `https://photos.cri.epita.fr/thumb/${student.login}`;
    } else {
      return '';
    }
  }

  public isNameValid(): boolean {
    return (
      this.event.infos.name !== undefined && this.event.infos.name.length !== 0
    );
  }

  public isDateValid(): boolean {
    return moment(this.event.infos.startDateCasted).isBefore(
      this.event.infos.endDateCasted
    );
  }

  public onModifiedRoomFilter(rooms: Room[]): void {
    this.event.infos.roomList = rooms;
  }

  public onModifiedGroupFilter(groups: Group[]): void {
    this.event.infos.groupList = groups;
  }

  public onModifiedTeamFilter(teams: Team[]): void {
    this.event.infos.teamList = teams;
  }

  public getRoomsTitleComponent(): string {
    return this.allRooms
      .filter((room: Room) =>
        this.event.infos.roomList.some(
          (infosRoom: Room) => infosRoom.id === room.id
        )
      )
      .map((room: Room) => room.name)
      .join(', ');
  }

  public getGroupsTitleComponent(): string {
    return this.allGroups
      .filter((group: Group) =>
        this.event.infos.groupList.some(
          (infosGroup: Group) => infosGroup.id === group.id
        )
      )
      .map((group: Group) => group.name)
      .join(', ');
  }

  public getTeamsTitleComponent(): string {
    return this.allTeams
      .filter((team: Team) =>
        this.event.infos.teamList.some(
          (infosteam: Team) => infosteam.id === team.id
        )
      )
      .map((team: Team) => team.name)
      .join(', ');
  }

  public onToggleRoomsPanel(isShown: boolean): void {
    this.multipleSelectPanelHandler.showRooms = isShown;
    Object.entries(this.multipleSelectPanelHandler).forEach(([key, value]) => {
      if (
        key !== 'showRooms' &&
        value &&
        this.multipleSelectPanelHandler.showRooms
      ) {
        this.multipleSelectPanelHandler[key] = false;
      }
    });
  }

  public onToggleGroupsPanel(isShown: boolean): void {
    this.multipleSelectPanelHandler.showGroups = isShown;
    Object.entries(this.multipleSelectPanelHandler).forEach(([key, value]) => {
      if (
        key !== 'showGroups' &&
        value &&
        this.multipleSelectPanelHandler.showGroups
      ) {
        this.multipleSelectPanelHandler[key] = false;
      }
    });
  }

  public onToggleTeamsPanel(isShown: boolean): void {
    this.multipleSelectPanelHandler.showTeams = isShown;
    Object.entries(this.multipleSelectPanelHandler).forEach(([key, value]) => {
      if (
        key !== 'showTeams' &&
        value &&
        this.multipleSelectPanelHandler.showTeams
      ) {
        this.multipleSelectPanelHandler[key] = false;
      }
    });
  }

  public onStartDateChanged(): void {
    const currHours: number = this.event.infos.startDateCasted.getHours();
    const currMin: number = this.event.infos.startDateCasted.getMinutes();
    const currDate: Date = this.ngbDateHelperService.toModel(
      this.startDatePicked
    );
    this.event.infos.startDateCasted = moment(currDate)
      .startOf('day')
      .add(currHours, 'hours')
      .add(currMin, 'minutes')
      .toDate();
  }

  public onStartHourChanged(): void {
    const currMin: number = this.event.infos.startDateCasted.getMinutes();
    this.event.infos.startDateCasted = moment(this.event.infos.startDateCasted)
      .startOf('day')
      .add(this.startHourFromSelect, 'hours')
      .add(currMin, 'minutes')
      .toDate();
  }

  public onStartMinChanged(): void {
    const currHours: number = this.event.infos.startDateCasted.getHours();
    this.event.infos.startDateCasted = moment(this.event.infos.startDateCasted)
      .startOf('day')
      .add(currHours, 'hours')
      .add(this.startMinFromSelect, 'minutes')
      .toDate();
  }

  public onEndDateChanged(): void {
    const currHours: number = this.event.infos.endDateCasted.getHours();
    const currMin: number = this.event.infos.endDateCasted.getMinutes();
    const currDate: Date = this.ngbDateHelperService.toModel(
      this.endDatePicked
    );
    this.event.infos.endDateCasted = moment(currDate)
      .startOf('day')
      .add(currHours, 'hours')
      .add(currMin, 'minutes')
      .toDate();
  }

  public onEndHourChanged(): void {
    const currMin: number = this.event.infos.endDateCasted.getMinutes();
    this.event.infos.endDateCasted = moment(this.event.infos.endDateCasted)
      .startOf('day')
      .add(this.endHourFromSelect, 'hours')
      .add(currMin, 'minutes')
      .toDate();
  }

  public onEndMinChanged(): void {
    const currHours: number = this.event.infos.endDateCasted.getHours();
    this.event.infos.endDateCasted = moment(this.event.infos.endDateCasted)
      .startOf('day')
      .add(currHours, 'hours')
      .add(this.endMinFromSelect, 'minutes')
      .toDate();
  }

  public generateIterable(length: number): Array<number> {
    return [...new Array(length).keys()];
  }

  public hasEventStarted(): boolean {
    return !moment(Date.now()).isBefore(
      moment(this.event.infos.startDateCasted)
    );
  }

  public isFormValid(): boolean {
    return (
      this.isNameValid() &&
      this.event.infos !== undefined &&
      this.event.infos.roomList !== undefined &&
      this.event.infos.roomList.length > 0 &&
      this.event.infos !== undefined &&
      this.event.infos.groupList !== undefined &&
      this.event.infos.groupList.length > 0 &&
      this.isDateValid() &&
      this.event.infos !== undefined &&
      this.event.infos.teamList !== undefined &&
      this.event.infos.teamList.length > 0
    );
  }

  public onAddStudentToAttendanceList(): void {
    const modalRef: NgbModalRef = this.modalService.open(
      AddStudentToPresenceListModalComponent
    );
    modalRef.componentInstance.studentsGroupList = this.event.infos.groupList;
    modalRef.componentInstance.initialStudentList = this.event.presenceList.presenceList.map(
      (presence: Presence) => presence.student
    );
    modalRef.result
      .then((studentList: Student[]): void => {
        const request = {
          presenceList: studentList.map((student: Student) => {
            return {
              presenceDate: new Date(),
              studentId: student.id
            } as MinifiedPresence;
          })
        } as AddStudentListToPresenceListRequest;
        this.eventsBusiness
          .addStudentListToAttendanceList(this.event.infos.id, request)
          .then(() => {
            this.toastrService.success(
              'La liste des étudiants a bien été mise à jour.',
              'Ajouter des étudiants à la liste de présence'
            );
            this.reloadEvent(this.event.infos.id);
          })
          .catch(err => {
            this.toastrService.error(
              "La liste des étudiants n'a pas pu être mise à jour.",
              'Ajouter des étudiants à la liste de présence'
            );
          });
      })
      .catch((): void => {
        this.toastrService.info(
          "Aucun étudiant n'a été ajouté à la liste de présence."
        );
      });
  }

  public trySubmit(): void {
    const request: UpdateEventRequest = Object.assign({}, {
      id: this.event.infos.id,
      name: this.event.infos.name,
      startDate: this.event.infos.startDateCasted,
      endDate: this.event.infos.endDateCasted,
      eventTypeId: this.event.infos.eventType.id,
      groupIdList: this.event.infos.groupList.map(el => el.id),
      roomIdList: this.event.infos.roomList.map(el => el.id),
      teamIdList: this.event.infos.teamList.map(el => el.id)
    } as UpdateEventRequest);
    this.eventsBusiness
      .updateEvent(request)
      .then(() => {
        this.toastrService.success(
          'Les modifications ont bien été enregistrées.',
          'Modifications enregistrées'
        );
        this.loadData(this.event.infos.id);
      })
      .catch(err => {
        console.log(err);
        this.toastrService.error(
          `Une erreur est survenue lors de la modification de l'événement.`,
          'Erreur interne'
        );
      });
  }

  public onDownloadAttendanceList(): void {
    if (this.event.presenceList.presenceList !== undefined) {
      const csv = this.parseAttendanceListToCsv();
      console.log(csv);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const filename = moment(this.event.infos.startDate)
        .format('DD/MM/YYYY hh:mm')
        .concat(' - ', this.event.infos.name.trim(), '.csv');
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  }

  private parseAttendanceListToCsv(): string {
    moment.locale('fr');
    const jsonFormattedForCsv: any[] = this.event.presenceList.presenceList.map(
      (presence: Presence) => {
        return {
          Login: presence.student.login,
          Prénom: presence.student.firstname,
          'Nom de famille': presence.student.lastname,
          "Groupe(s) de l'étudiant": presence.student.groupList
            .map(el => el.name.toUpperCase())
            .join('/ '),
          "Date d'enregistrement": moment(presence.presenceDate).format(
            'DD/MM/YYYY hh:mm'
          )
        };
      }
    );
    const csv = this.fromJsonToCsv(jsonFormattedForCsv);
    return csv;
  }

  private fromJsonToCsv(json: any[]): string {
    const header: string = Object.getOwnPropertyNames(json[0])
      .join(',')
      .concat(this.getEnvNewLine());
    return json.reduce((acc: string, obj: any) => {
      return acc.concat(
        Object.getOwnPropertyNames(obj)
          .map(property => obj[property])
          .join(',')
          .concat(this.getEnvNewLine())
      );
    }, header);
  }

  private getEnvNewLine(): string {
    return process.platform === 'win32' ? '\r\n' : '\n';
  }

  private initComponent(): void {
    this.multipleSelectPanelHandler = {
      showGroups: false,
      showRooms: false,
      showTeams: false
    };
    const currDate: Date = new Date();
    this.startDatePicked = {
      day: currDate.getDate(),
      month: currDate.getMonth() + 1,
      year: currDate.getFullYear()
    };
    this.endDatePicked = {
      day: currDate.getDate(),
      month: currDate.getMonth() + 1,
      year: currDate.getFullYear()
    };
    this.startHourFromSelect = '10';
    this.startMinFromSelect = '0';
    this.endHourFromSelect = '18';
    this.endMinFromSelect = '0';
    this.activatedRoute.paramMap
      .pipe(
        switchMap(
          (params: ParamMap): Observable<number> => {
            return of<number>(parseInt(params.get('id'), 10));
          }
        )
      )
      .subscribe((id: number) => {
        this.loadData(id);
      });
  }

  private reloadEvent(eventId: number): void {
    this.eventsBusiness
      .getEvent(eventId)
      .then((res: FullEvent) => {
        this.event = res;
      })
      .catch(err => {
        console.log(err);
      });
  }

  private loadData(eventId: number): void {
    Promise.all([
      this.eventsBusiness.getEvent(eventId),
      this.roomsBusiness.getRooms(),
      this.groupsBusiness.getGroups(),
      this.teamsBusiness.getTeams(),
      this.eventsBusiness.getEventTypes()
    ])
      .then(
        (
          res: [
            FullEvent,
            GetRoomsResponse,
            GetGroupsResponse,
            GetTeamsReponse,
            GetEventTypesResponse
          ]
        ) => {
          this.event = res[0];
          this.customRouterService.setNewComponentName(res[0].infos.name);
          this.startDatePicked = {
            day: this.event.infos.startDateCasted.getDate(),
            month: this.event.infos.startDateCasted.getMonth() + 1,
            year: this.event.infos.startDateCasted.getFullYear()
          };

          this.startHourFromSelect = `${this.event.infos.startDateCasted.getHours()}`;
          this.startMinFromSelect = `${this.event.infos.startDateCasted.getMinutes()}`;
          this.endHourFromSelect = `${this.event.infos.endDateCasted.getHours()}`;
          this.endMinFromSelect = `${this.event.infos.endDateCasted.getMinutes()}`;

          this.endDatePicked = {
            day: this.event.infos.endDateCasted.getDate(),
            month: this.event.infos.endDateCasted.getMonth() + 1,
            year: this.event.infos.endDateCasted.getFullYear()
          };

          this.allRooms = res[1].roomList;

          this.allGroups = res[2].groupList;
          this.allTeams = res[3].teamList;
          this.allTypes = res[4].eventTypeList;
        }
      )
      .catch(err => {
        this.toastrService.error(
          'Impossible de récupérer les données depuis le serveur. Contactez un administrateur.',
          'Erreur interne'
        );
        console.log(err);
      })
      .finally(() => {
        this.customRouterService.setLoadingState(false);
        this.componentReady = true;
        setTimeout(() => {
          this.initAnimation();
        }, 500);
      });
  }

  private initAnimation(): void {
    document.querySelectorAll('.appearance-opacity-animation').forEach(el => {
      this.animationService.animateOpacity(el as HTMLElement, 0);
    });
    document
      .querySelectorAll('.appearance-translate-opacity-animation')
      .forEach(el => {
        this.animationService.animateOpacityTranslateFromBottom(
          el as HTMLElement,
          0
        );
      });
  }
}
