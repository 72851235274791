import { Injectable } from '@angular/core';
import { CircularQueue } from '../misc/data-structures/circularqueue';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class CustomRouterService {

    public previsousUrls: CircularQueue<string>;
    public currentPrettyNameComponent$: Subject<string>;
    public loading$: Subject<boolean>;

    constructor(
        private router: Router
    ) {
        this.previsousUrls = new CircularQueue(10);
        this.currentPrettyNameComponent$ = new Subject();
        this.loading$ = new Subject();
        this.router = router;
    }

    public previousRouteMatchesSubUrl(subUrl: string): boolean {
        return new RegExp(`^${subUrl}`, 'i').test(this.previsousUrls.anteLastOut());
    }

    public setNewComponentName(name: string): void {
        this.currentPrettyNameComponent$.next(name);
    }

    public setLoadingState(loading: boolean): void {
        this.loading$.next(loading);
    }

    public resetComponentName(): void {
        this.currentPrettyNameComponent$.next('');
    }

    public navigate(args: Array<any>): void {
        this.resetComponentName();
        this.setLoadingState(true);
        this.setNewComponentName('');
        this.router.navigate(args);
    }
}
