import { Injectable } from '@angular/core';
import { AdminData } from '../data-access/admin.data';
import { ActivateAdminRequest } from 'src/app/models/requests/admin/activateAdminRequest.model';
import { DeactivateAdminRequest } from 'src/app/models/requests/admin/deactivateAdminRequest.model';
import { SearchForUsersResponse } from 'src/app/models/responses/admin/searchForUsersResponse.model';
import { SearchForUsersRequest } from 'src/app/models/requests/admin/searchForUsersRequest.model';

@Injectable()
export class AdminBusiness {

    private adminData: AdminData;

    public constructor(adminData: AdminData) {
        this.adminData = adminData;
    }

    public async activateAdmin(adminLogin: string): Promise<void> {
      return this.adminData.activateAdmin({ login: adminLogin } as ActivateAdminRequest);
    }

    public async deactivateAdmin(adminLogin: string): Promise<void> {
      return this.adminData.deactivateAdmin({ login: adminLogin } as DeactivateAdminRequest);
    }

    public async searchForUsers(search: string): Promise<SearchForUsersResponse> {
      return this.adminData.searchForUsers({ search } as SearchForUsersRequest);
    }
}
