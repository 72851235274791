import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateHelperService {

  constructor() { }

  public fromModel(date: Date): NgbDateStruct {
    return date ? {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    } : null;
  }

  // gets the UNIX epoch time from NgbDateStruct typed date
  public toUTCDate(date: NgbDateStruct): number {
    return date ? Date.UTC(date.year, date.month - 1, date.day) : null;
  }

  public toModel(date: NgbDateStruct): Date {
    return date ? new Date(date.year, date.month - 1, date.day) : null;
  }

  public toStringify(date: NgbDateStruct): string {
    const currDate = date ? JSON.stringify(new Date(date.year, date.month - 1, date.day, 1)) : null;
    if (currDate === null) {
      return null;
    } else {
      return currDate.toString().substring(1, currDate.toString().length - 1);
    }
  }

  public toStringifyFromDate(date: Date): string {
    const currDate = date ? JSON.stringify(date) : null;
    if (currDate === null) {
      return null;
    } else {
      return currDate.toString().substring(1, currDate.toString().length - 1);
    }
  }
}
