import { Component, OnInit } from "@angular/core";
import { CustomRouterService } from "src/app/services/custom-router.service";
import { EventsBusiness } from "src/app/api/business/events.business";
import { GetEventsSingleEvent } from "src/app/models/responses/events/getEvents/getEventsSingleEvent.model";
import { GetEventsRequest } from "src/app/models/requests/events/getEventsRequest.model";
import { NgbDateStruct, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateHelperService } from 'src/app/services/ngbDateHelper.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Room } from 'src/app/models/responses/rooms/room.model';
import { RoomsBusiness } from 'src/app/api/business/rooms.business';
import { Group } from 'src/app/models/responses/groups/group.model';
import { GroupsBusiness } from 'src/app/api/business/groups.business';
import { MultipleSelectPanelHandler } from 'src/app/models/multipleSelectPanelHandler.model';
import { PostEventRequest } from 'src/app/models/requests/events/postEventRequest.model';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal';
import { ModalService } from 'src/app/services/modal.service';
import { SortEnum } from 'src/app/models/requests/sortEnum.enum';
import { Router } from '@angular/router';
import { Team } from 'src/app/models/responses/teams/team.model';
import { TeamsBusiness } from 'src/app/api/business/teams.business';

@Component({
  selector: "app-eventList",
  styleUrls: ["./eventList.component.scss"],
  templateUrl: "./eventList.component.html"
})
export class EventListComponent implements OnInit {
  public eventList: GetEventsSingleEvent[];
  public totalNumberEvents: number;
  public allRooms: Room[];
  public allGroups: Group[];
  public allTeams: Team[];

  public searchEventByTitleString: string;
  public startDatePicked: NgbDateStruct;
  public endDatePicked: NgbDateStruct;
  public loading: boolean;
  public multipleSelectPanelHandler: MultipleSelectPanelHandler;
  public currentPage: number;

  public limit: string;
  public offset: string;
  public search: string;
  public startDate: string;
  public endDate: string;
  public roomIds: number[];
  public groupIds: number[];
  public typeId: number;

  private request: GetEventsRequest;

  public constructor(
    private customRouterService: CustomRouterService,
    private eventsBusiness: EventsBusiness,
    private ngbDateHelperService: NgbDateHelperService,
    private toastrService: ToastrService,
    private roomsBusiness: RoomsBusiness,
    private groupsBusiness: GroupsBusiness,
    private teamsBusiness: TeamsBusiness,
    private modalService: ModalService
  ) {
    this.customRouterService = customRouterService;
    this.eventsBusiness = eventsBusiness;
    this.ngbDateHelperService = ngbDateHelperService;
    this.toastrService = toastrService;
    this.roomsBusiness = roomsBusiness;
    this.groupsBusiness = groupsBusiness;
    this.teamsBusiness = teamsBusiness;
    this.modalService = modalService;
  }

  public ngOnInit(): void {
    this.customRouterService.setLoadingState(true);
    this.initComponent();
    this.initDebouncer();
    this.initData();
    this.loadData();
  }

  public reloadEvents(): void {
    this.loading = true;
    // this.eventList = [];
    if (this.startDatePicked !== undefined && this.startDatePicked !== null) {
      this.startDate = this.ngbDateHelperService.toStringify(this.startDatePicked);
    } else {
      this.startDate = undefined;
    }
    if (this.endDatePicked !== undefined && this.endDatePicked !== null) {
      this.endDate = this.ngbDateHelperService.toStringify(this.endDatePicked);
    } else {
      this.endDate = undefined;
    }
    this.request = Object.assign(this.request, {
      offset: parseInt(this.offset, 10),
      limit: parseInt(this.limit, 10),
      search: this.search,
      startDate: this.startDate,
      endDate: this.endDate,
      roomId: this.roomIds,
      groupId: this.groupIds,
      typeId: this.typeId
    });
    this.loadData();
  }

  public areAllRoomsLoaded(): boolean {
    return this.allRooms !== undefined && this.allRooms.length > 0;
  }

  public areAllGroupsLoaded(): boolean {
    return this.allGroups !== undefined && this.allGroups.length > 0;
  }

  public areAllTeamsLoaded(): boolean {
    return this.allTeams !== undefined && this.allTeams.length > 0;
  }

  public onModifiedRoomFilter(rooms: Room[]): void {
    this.roomIds = rooms.map((room: Room) => room.id);
    this.reloadEvents();
  }

  public onModifiedGroupFilter(groups: Group[]): void {
    this.groupIds = groups.map((group: Group) => group.id);
    this.reloadEvents();
  }

  public onToggleRoomsPanel(isShown: boolean): void {
    this.multipleSelectPanelHandler.showRooms = isShown;
    Object.entries(this.multipleSelectPanelHandler)
    .forEach(([key, value]) => {
      if (key !== 'showRooms' && value && this.multipleSelectPanelHandler.showRooms) {
        this.multipleSelectPanelHandler[key] = false;
      }
    });
  }

  public onToggleGroupsPanel(isShown: boolean): void {
    this.multipleSelectPanelHandler.showGroups = isShown;
    Object.entries(this.multipleSelectPanelHandler)
    .forEach(([key, value]) => {
      if (key !== 'showGroups' && value && this.multipleSelectPanelHandler.showGroups) {
        this.multipleSelectPanelHandler[key] = false;
      }
    });
  }

  public onToggleTeamsPanel(isShown: boolean): void {
    this.multipleSelectPanelHandler.showTeams = isShown;
    Object.entries(this.multipleSelectPanelHandler)
    .forEach(([key, value]) => {
      if (key !== 'showTeams' && value && this.multipleSelectPanelHandler.showTeams) {
        this.multipleSelectPanelHandler[key] = false;
      }
    });
  }

  public stringifySpeakers(event: GetEventsSingleEvent): string {
    return event.speakerList.map((speaker) => speaker.name).join(', ');
  }

  public stringifyRooms(event: GetEventsSingleEvent): string {
    return event.roomList.map((room: Room) => room.name).join(', ');
  }

  public stringifyGroups(event: GetEventsSingleEvent): string {
    return event.groupList.map((group: Group) => group.name).join(', ');
  }

  public stringifyTeams(event: GetEventsSingleEvent): string {
    return event.teamList.map((team: Team) => team.name).join(', ');
  }

  public isLoading(): boolean {
    return (this.eventList.length === 0 && this.searchEventByTitleString.length > 0) ||
    (this.eventList.length === 0 && this.startDate !== undefined)
  }

  public htmlLogs(args: any[]): void {
    console.log(args);
  }

  public getDataRepresentation(limit: number | string): Array<number> {
    const dataRepresentation = [];
    for (let i = 0; i < (typeof limit === 'string' ? parseInt(limit, 10): limit); i++) { dataRepresentation.push(i); }
    return dataRepresentation;
  }

  public onPageChanged(currentPage: number): void {
    this.offset = (parseInt(this.limit, 10) * (currentPage - 1)).toString();
    this.reloadEvents();
  }

  public onAddEventTriggered(request: PostEventRequest): void {
    if (request === null) {
      this.toastrService.info(`Aucun événement n'a été ajouté.`, 'Ajouter un événement');
    } else {
      this.eventsBusiness.postEvent(request)
      .then(() => {
        this.toastrService.success(`L'événement "${request.name}" a bien été ajouté !`, 'Ajouter un événement');
        this.reloadEvents();
      })
      .catch((err) => {
        this.toastrService.error(`Erreur lors de l'ajout de l'événement.`, 'Ajouter un événement');
      });
    }
  }

  public onDeleteEvent(currEvent: GetEventsSingleEvent): void {
    const modalRef: NgbModalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.title = `Confirmer la suppression de l'événement`;
    modalRef.componentInstance.message = `Vous êtes sur le point de supprimer l'événement "${currEvent.name}".<br>
    Êtes-vous sûr de vouloir effectuer cette action ?`;
    modalRef.result
    .then((): void => {
      this.eventsBusiness.deleteEvent(currEvent.id)
      .then(() => {
        this.toastrService.success(`L'événement ${currEvent.name} a bien été supprimé.`, `Suppression d'un événement`);
        this.reloadEvents();
      })
      .catch((err) => {
        this.toastrService.error(`Erreur lors de la suppression de l'événement "${currEvent.name}".`, `Suppression d'un événement`);
      });
    })
    .catch((): void => {
      this.toastrService.info(`L'événement n'a pas été supprimé.`, `Suppression d'un événement`);
    });
  }

  public onResetDateFilter(): void {
    this.startDatePicked = undefined;
    this.endDatePicked = undefined;
    this.reloadEvents();
  }

  public onChangeFilteringOrderDate(): void {
    if (this.request.sortByDate === SortEnum.ASCENDING_ORDER) {
      this.request.sortByDate = SortEnum.DESCENDING_ORDER;
    } else {
      this.request.sortByDate = SortEnum.ASCENDING_ORDER;
    }
    this.request.sortByName = undefined;
    this.reloadEvents();
  }

  public onChangeFilteringOrderName(): void {
    if (this.request.sortByName === SortEnum.ASCENDING_ORDER) {
      this.request.sortByName = SortEnum.DESCENDING_ORDER;
    } else {
      this.request.sortByName = SortEnum.ASCENDING_ORDER;
    }
    this.request.sortByDate = undefined;
    this.reloadEvents();
  }

  public onDetailsClick(eventId: number): void {
    this.customRouterService.navigate(['events', eventId]);
  }

  private initComponent(): void {
    this.loading = false;
    this.currentPage = 1;
    this.limit = '10';
    this.offset = '0';
    this.searchEventByTitleString = '';
    this.multipleSelectPanelHandler = {
      showGroups: false,
      showRooms: false,
      showTeams: false
    };
  }

  private initData(): void {
    this.search = '';
    this.totalNumberEvents = 0;
    this.mockDate();
    this.roomIds = [];
    this.groupIds = [];
    this.allRooms = [];
    this.allGroups = [];
    this.allTeams = [];
    this.request = Object.assign(new GetEventsRequest(), {
      roomId: [],
      groupId: [],
      offset: parseInt(this.offset, 10),
      limit: parseInt(this.limit, 10)
    });
  }

  private mockDate(): void {
    const datas = [];
    for (let i = 0; i < 10; i++) {
      const newElem = new GetEventsSingleEvent();
      newElem.name = '';
      newElem.roomList = [];
      newElem.speakerList = [];
      newElem.teamList = [];
      newElem.id = i;
      newElem.startDate = '';
      datas.push(newElem);
    }
    this.eventList = datas;
  }

  private initDebouncer(): void {
    const searchInput = document.getElementById('searchEventByTitle');
    const observableValue = fromEvent(searchInput, 'keyup')
    const debouncedInput = observableValue.pipe(
      debounceTime(750),
      distinctUntilChanged()
    );
    debouncedInput.subscribe(val => {
      this.search = this.searchEventByTitleString.trim();
      if (this.isSearchStringOk()) {
        this.eventList = [];
        this.reloadEvents();
      }
    });
  }

  private isSearchStringOk(): boolean {
    return this.search !== undefined &&
      this.search !== null &&
      this.checkAlphaNumeric();
  }

  private checkAlphaNumeric(): boolean {
    if (this.search.search(new RegExp(new RegExp('^[a-zA-Z0-9\-, ]*$'), 'g')) !== -1) {
      return true;
    } else {
      this.toastrService.info(
        'Seuls les caractères alphanumériques, les tirets, les espaces et les virgules sont autorisés.',
        'Caractères autorisés'
      );
      return false;
    }
  }

  private loadData(): void {
    this.loading = true;
    Promise.all([
      this.eventsBusiness.getEvents(this.request),
      this.roomsBusiness.getRooms(),
      this.groupsBusiness.getGroups(),
      this.teamsBusiness.getTeams()
    ])
    .then((responses: any[]) => {
      this.eventList = responses[0].eventList;
      this.totalNumberEvents = responses[0].totalNumberEvents;

      this.allRooms = responses[1].roomList;

      this.allGroups = responses[2].groupList;

      this.allTeams = responses[3].teamList;

      this.loading = false;
    })
    .catch((err) => {
      this.loading = true;
      this.toastrService.error('Impossible de récupérer les données depuis le serveur. Contactez un administrateur.', 'Erreur interne');
      console.log(err);
    })
    .finally(() => {
      this.customRouterService.setNewComponentName('Liste des événements');
      this.customRouterService.setLoadingState(false);
    })
  }
}
