import { SortEnum } from '../sortEnum.enum';

export class GetEventsRequest {
    public search?: string;
    public startDate?: string;
    public endDate?: string;
    public roomId?: number[];
    public groupId?: number[];
    public typeId?: number;
    public offset?: number;
    public limit?: number;
    public sortByDate?: SortEnum;
    public sortByName?: SortEnum;
}
